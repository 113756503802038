import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

export default function PostEventEmailModal(props) {
  const [state, setState] = useState({});

  useEffect(() => {
    setState((s) => ({
      ...s,
      ...props.event,
      formattedDate: `${props.event.date.slice(8, 10)}.${props.event.date.slice(
        5,
        7
      )}`,
    }));
  }, [props.event]);

  const handleConfirm = async () => {
    setState((s) => ({
      ...s,
      isLoading: true,
    }));
    const body = {
      eventId: state.id,
      venueName: state.venueName,
      formattedDate: state.formattedDate,
    };
    await props.handleConfirm(body);
    props.handleClose();
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>Post-Event Feedback Emails</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to send post-event feedback emails for{" "}
          <b>{state.name}</b> at <b>{state.venueName}</b> on{" "}
          <b>{state.formattedDate}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
