export default function readStreamAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = function (event) {
      const base64 = btoa(event.target.result);
      resolve(base64);
    };
    reader.onerror = function () {
      reject();
    };
  });
}
