import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import socketIOClient from "socket.io-client";
import { getEvents, getFeed, getPlaylists, createOrder } from "../api";
import KaraokeModal from "../components/KaraokeModal";
import Loader from "../components/common/Loader";
import config from "../config";

export default function KaraokePage() {
  const [state, setState] = useState({});

  const handleCreate = () => {
    setState((s) => ({
      ...s,
      open: true,
      handleConfirm: createOrder,
    }));
  };

  const handleClose = () => {
    setState((s) => ({
      ...s,
      open: false,
      handleConfirm: undefined,
    }));
  };

  useEffect(() => {
    (async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        const todayDate = new Date();
        if (todayDate.getHours() < 12) {
          todayDate.setDate(todayDate.getDate() - 1);
        }
        const today =
          todayDate.getFullYear() +
          "-" +
          (todayDate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          todayDate.getDate().toString().padStart(2, "0");
        const data =
          user.role === "karaoke"
            ? await getEvents({ fromDate: today, toDate: today })
            : [];
        const item = data
          .filter((x) => x.venueId === user.venueId && x.isKaraoke)
          .slice(-1)[0];
        const nowTime = new Date().getTime();
        const playlistId =
          item &&
          (await getPlaylists(item.id)).playlists.filter(
            (x) => nowTime >= x.startTime && nowTime <= x.endTime
          )[0]?.id;
        setState((s) => ({
          ...s,
          item,
          data: [],
          venueId: user.venueId,
          playlistId,
          role: user.role,
        }));
      }
    })();
  }, []);

  const addOrUpdateOrder = (socketItem) =>
    setState((state) => {
      const data = [...state.data];
      const index = data.indexOf(
        data.find((x) => x.order.id === socketItem.order.id)
      );
      if (index > -1) {
        data.splice(index, 1, socketItem);
      } else {
        data.splice(0, 0, socketItem);
      }
      return { ...state, data };
    });

  useEffect(() => {
    (async () => {
      if (!state.item) return;
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        let data =
          // user.role === "karaoke" ? await getFeed(state.item.eventId) : [];
          user.role === "karaoke"
            ? await getFeed("eba3b797-3f05-4492-b1a3-7b426665dadc")
            : [];
        data = data
          .filter((x) => ["pending", "confirmed"].includes(x.status))
          .map((order) => ({
            order,
          }));
        setState((s) => ({
          ...s,
          data,
        }));

        const socketByEventId = socketIOClient(config.wsUrl, {
          query: {
            eventId: state.item.id,
          },
        });

        socketByEventId.on("order_created", addOrUpdateOrder);
        socketByEventId.on("order_approved", addOrUpdateOrder);
        socketByEventId.on("order_completed", addOrUpdateOrder);
      }
    })();
  }, [state.item]);

  return (
    <>
      <Grid
        container
        p={4}
        spacing={4}
        sx={{ maxWidth: "1600px", paddingTop: "80px" }}
        justifyContent="center"
      >
        {["karaoke"].includes(state.role) && state.playlistId && (
          <Grid item xs={12}>
            <Button type="submit" onClick={handleCreate} variant="contained">
              New Request
            </Button>
          </Grid>
        )}
        {state.data ? (
          <>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ padding: 4 }}>
                <Typography align="center" sx={{ marginBottom: 3 }}>
                  Pending
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>Artist - Track</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.data
                      .filter((x) => x.order.status === "pending")
                      .map((item) => (
                        <TableRow key={item.order.id}>
                          <TableCell style={{ width: 0 }}>
                            {new Date(item.order.updatedAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </TableCell>
                          <TableCell>{item.order.item}</TableCell>
                          <TableCell>{item.order.userName}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ padding: 4 }}>
                <Typography align="center" sx={{ marginBottom: 3 }}>
                  Completed
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>Artist - Track</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.data
                      .filter((x) => x.order.status === "confirmed")
                      .map((item) => (
                        <TableRow key={item.order.id}>
                          <TableCell style={{ width: 0 }}>
                            {new Date(item.order.updatedAt).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </TableCell>
                          <TableCell>{item.order.item}</TableCell>
                          <TableCell>{item.order.userName}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        ) : (
          <Loader />
        )}
      </Grid>
      {state.open && (
        <KaraokeModal
          eventId={state.item.id}
          venueId={state.venueId}
          playlistId={state.playlistId}
          promotion={state.promotion}
          handleConfirm={state.handleConfirm}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
