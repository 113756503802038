import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  CardContent,
  CardActions,
  Grid,
  Card,
  CardMedia,
  IconButton,
} from "@mui/material";
import {
  getVenuesWithLogos,
  createVenue,
  updateVenue,
  deleteVenue,
} from "../api";
import { Delete, Edit } from "@mui/icons-material";
import VenueModal from "../components/VenueModal";
import DeleteModal from "../components/DeleteModal";
import Loader from "../components/common/Loader";

export default function VenuesPage() {
  const [state, setState] = useState({});

  const handleCreate = () => {
    setState((s) => ({
      ...s,
      open: true,
      venue: undefined,
      handleConfirm: async (body) => {
        const venue = await createVenue(body);
        setState((s) => ({
          ...s,
          data: [venue, ...s.data],
        }));
      },
    }));
  };

  const handleEdit = (item) => () => {
    setState((s) => ({
      ...s,
      open: true,
      venue: item,
      handleConfirm: async (body) => {
        const venue = await updateVenue(body);
        s.data.splice(
          s.data.indexOf(s.data.find((x) => x.id === venue.id)),
          1,
          venue
        );
      },
    }));
  };

  const handleClose = () => {
    setState((s) => ({
      ...s,
      open: false,
      venue: undefined,
      handleConfirm: undefined,
    }));
  };

  const handleDelete = (item) => async () => {
    setState((s) => ({
      ...s,
      delete: true,
      deleteName: item.name,
      handleDeleteConfirm: async () => {
        await deleteVenue(item);
        setState((s) => ({
          ...s,
          data: [...s.data.filter((x) => x.id !== item.id)],
        }));
      },
    }));
  };

  const handleDeleteClose = () => {
    setState((s) => ({
      ...s,
      delete: false,
      deleteName: undefined,
      handleDeleteConfirm: undefined,
    }));
  };

  useEffect(() => {
    (async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        const data = await getVenuesWithLogos();

        setState((s) => ({
          ...s,
          data,
          venueId: user.venueId,
          role: user.role,
        }));
      }
    })();
  }, []);

  return (
    <>
      <Grid
        container
        p={4}
        spacing={4}
        sx={{ maxWidth: "1600px", paddingTop: "80px" }}
        justifyContent="center"
      >
        {["admin"].includes(state.role) && (
          <Grid item xs={12}>
            <Button type="submit" onClick={handleCreate} variant="contained">
              Create Venue
            </Button>
          </Grid>
        )}
        {state.data ? (
          state.data.map((item) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              key={item.id}
              sx={{ background: "transparent" }}
            >
              <Card variant="outlined" sx={{ backgroundColor: "#4b445b" }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={item.logo.uri}
                  alt={item.name}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      height: "64px",
                    }}
                  >
                    {item.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  {["admin"].includes(state.role) && (
                    <IconButton
                      aria-label="edit"
                      onClick={handleEdit(item)}
                      color="secondary"
                      sx={{ marginLeft: "auto" }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  {["admin"].includes(state.role) && (
                    <IconButton
                      aria-label="delete"
                      onClick={handleDelete(item)}
                      color="secondary"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Loader />
        )}
      </Grid>
      {state.open && (
        <VenueModal
          venue={state.venue}
          handleConfirm={state.handleConfirm}
          handleClose={handleClose}
        />
      )}
      {state.delete && (
        <DeleteModal
          name={state.deleteName}
          handleConfirm={state.handleDeleteConfirm}
          handleClose={handleDeleteClose}
        />
      )}
    </>
  );
}
