import demo from "./demo";
import dev from "./dev";
import prod from "./prod";

const env = process.env.REACT_APP_ENV || "prod";

const configs = { demo, dev, prod };
const config = configs[env];

export default config;
