import { createTheme } from "@mui/material/styles";

// const customStyles = {
//   gradient: {
//     background: "linear-gradient(to bottom , #190d28, #062332);",
//     foreground: "linear-gradient(to bottom , #4D4C6F, #503A52);",
//   },
// };

export const BaseTheme = createTheme({
  palette: {
    primary: {
      main: "#4b445b",
    },
    secondary: {
      main: "#d8c8f4",
    },
    text: {
      primary: "#000",
      secondary: "#222",
      disabled: "#9575cd",
      hint: "#d1c4e9",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#ede7f6",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#4b445b",
          color: "#ede7f6",
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       "& label": {
    //         color: "#d8c8f4",
    //       },
    //       "& label.Mui-focused": {
    //         color: "#d8c8f4",
    //       },
    //       "& .MuiInput-underline:after": {
    //         borderBottomColor: "#d8c8f4",
    //       },
    //       "& .MuiOutlinedInput-root": {
    //         "& fieldset": {
    //           borderColor: "#d8c8f4",
    //         },
    //         "&:hover fieldset": {
    //           borderColor: "#d8c8f4",
    //         },
    //         "&.Mui-focused fieldset": {
    //           borderColor: "#d8c8f4",
    //         },
    //       },
    //     },
    //   },
    // },
  },

  // components: {
  //   // MuiPaper: {
  //   //   styleOverrides: {
  //   //     root: {
  //   //       // backgroundImage: `url(${"/static/media/bar.jpeg"})`,
  //   //       // background: customStyles.gradient.background,
  //   //     },
  //   //   },
  //   // },
  //   // MuiIconButton: {
  //   //   styleOverrides: {
  //   //     root: {
  //   //       color: "#ede7f6",
  //   //       "&:hover": {
  //   //         backgroundColor: "#857e94",
  //   //       },
  //   //     },
  //   //   },
  //   // },
  //   // MuiLink: {
  //   //   styleOverrides: {
  //   //     root: {
  //   //       textDecoration: "none",
  //   //       color: "#ede7f6",
  //   //       "&:hover": {
  //   //         backgroundColor: "#857e94",
  //   //       },
  //   //     },
  //   //   },
  //   // },
  //   // MuiCheckbox: {
  //   //   styleOverrides: {
  //   //     root: {
  //   //       color: "#ede7f6",
  //   //       "&:hover": {
  //   //         backgroundColor: "#857e94",
  //   //       },
  //   //       "&.Mui-checked": {
  //   //         color: "#ede7f6",
  //   //       },
  //   //     },
  //   //   },
  //   // },
  // },
});
