import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  CardActions,
  Grid,
  Card,
  Typography,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  getPlaylists,
  createPlaylist,
  editPlaylist,
  deletePlaylist,
} from "../api";
import { Delete, Edit, Download } from "@mui/icons-material";
import PlaylistModal from "../components/PlaylistModal";
import DeleteModal from "../components/DeleteModal";
import Loader from "../components/common/Loader";

export default function PlaylistsPage() {
  const { eventId } = useParams();

  const [state, setState] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setState((s) => ({
        ...s,
        role: user.role,
      }));
    }
  }, []);

  const handleCreate = () => {
    setState((s) => ({
      ...s,
      open: true,
      playlist: undefined,
      handleConfirm: async (body) => {
        const data = await createPlaylist(body);
        setState((s) => ({
          ...s,
          data,
        }));
      },
    }));
  };

  const handleEdit = (item) => () => {
    setState((s) => ({
      ...s,
      open: true,
      playlist: item,
      handleConfirm: async (body) => {
        const data = await editPlaylist(body);
        setState((s) => ({
          ...s,
          data,
        }));
      },
    }));
  };

  const handleDownload = (item) => () => {
    const element = document.createElement("a");
    const file = new Blob([item.playlist.map((x) => x.item).join("\n")], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "playlist.txt";
    document.body.appendChild(element);
    element.click();
  };

  const handleClose = () => {
    setState((s) => ({
      ...s,
      open: false,
      playlist: undefined,
      handleConfirm: undefined,
    }));
  };

  const handleDelete = (item) => async () => {
    setState((s) => ({
      ...s,
      delete: true,
      deleteName: item.name,
      handleDeleteConfirm: async () => {
        await deletePlaylist(item);
        setState((s) => ({
          ...s,
          data: [...s.data.filter((x) => x.id !== item.id)],
        }));
      },
    }));
  };

  const handleDeleteClose = () => {
    setState((s) => ({
      ...s,
      delete: false,
      deleteName: undefined,
      handleDeleteConfirm: undefined,
    }));
  };

  useEffect(() => {
    (async () => {
      const playlistData = await getPlaylists(eventId);
      setState((s) => ({
        ...s,
        data: playlistData.playlists,
        eventDate: playlistData.eventDate,
        venueId: playlistData.venueId,
        venueCurrency: playlistData.venueCurrency,
      }));
    })();
  }, [eventId]);

  return (
    <>
      <Grid
        container
        p={4}
        spacing={4}
        sx={{ maxWidth: "1600px", paddingTop: "80px" }}
        justifyContent="center"
      >
        {state.data ? (
          <>
            <Grid item xs={12}>
              <Button type="submit" onClick={handleCreate} variant="contained">
                Create Playlist
              </Button>
            </Grid>
            {state.data.map((item) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                key={item.id}
                sx={{ background: "transparent" }}
              >
                <Card variant="outlined" sx={{ backgroundColor: "#4b445b" }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color="secondary"
                    >
                      {new Date(item.startTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -{" "}
                      {new Date(item.endTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 10,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        height: "200px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.playlist.map((x) => x.item).join("\n")}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {["admin"].includes(state.role) && (
                      <IconButton
                        aria-label="download"
                        onClick={handleDownload(item)}
                        color="secondary"
                        sx={{ marginLeft: "auto" }}
                      >
                        <Download />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="edit"
                      onClick={handleEdit(item)}
                      color="secondary"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={handleDelete(item)}
                      color="secondary"
                    >
                      <Delete />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          <Loader />
        )}
      </Grid>
      {state.open && (
        <PlaylistModal
          eventId={eventId}
          venueId={state.venueId}
          venueCurrency={state.venueCurrency}
          playlist={state.playlist}
          handleConfirm={state.handleConfirm}
          handleClose={handleClose}
          eventDate={state.eventDate}
        />
      )}
      {state.delete && (
        <DeleteModal
          name={state.deleteName}
          handleConfirm={state.handleDeleteConfirm}
          handleClose={handleDeleteClose}
        />
      )}
    </>
  );
}
