import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import { ThemeProvider } from "@mui/material/styles";
import { BaseTheme } from "./themes/BaseTheme";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Layout from "./UI/Layout";
import UserContextProvider from "./contexts/UserContextProvider";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={BaseTheme}>
      <UserContextProvider>
        <BrowserRouter>
          <Provider store={store}>
            <Layout>
              <App />
            </Layout>
          </Provider>
        </BrowserRouter>
      </UserContextProvider>
    </ThemeProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
