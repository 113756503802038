import { SpinnerCircular } from "spinners-react";

const Loader = () => (
  <SpinnerCircular
    size={70}
    thickness={200}
    color="#D8C8F4"
    secondaryColor="#342F3F"
    style={{
      margin: "30px",
    }}
  />
);

export default Loader;
