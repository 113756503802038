import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Typography,
} from "@mui/material";
import { getUserPlaylists } from "../api";

export default function CopyPlaylistModal(props) {
  const [state, setState] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setState((s) => ({
        ...s,
        role: user.role,
      }));
    }
  }, []);

  useEffect(() => {
    (async () => {
      const userPlaylists = (
        await getUserPlaylists({
          eventId: props.eventId,
          venueId: props.venueId,
        })
      ).filter((x) => x.id !== props.playlistId);
      setState((s) => ({
        ...s,
        userPlaylists,
      }));
    })();
  }, [props.playlistId, props.eventId, props.venueId]);

  const handleConfirm = async () => {
    await props.handleConfirm(state.playlist);
    props.handleClose();
  };

  const handleDownload = async () => {
    const element = document.createElement("a");
    const file = new Blob([state.playlist.map((x) => x.item).join("\n")], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "playlist.txt";
    document.body.appendChild(element);
    element.click();
  };

  const handleSelectedPlaylistChange = async (e, value) => {
    const playlist = state.userPlaylists.find((x) => x.id === value.value)
      .playlist;

    setState((s) => ({
      ...s,
      playlist,
    }));
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
          width: "500px",
          height: "500px",
        },
      }}
    >
      <DialogTitle>Copy Playlist</DialogTitle>
      <DialogContent>
        {!!state.userPlaylists ? (
          <Autocomplete
            disablePortal
            id="selectedPlaylist-dropdown"
            options={state.userPlaylists.map((x) => ({
              label: `${x.venueName} / ${x.eventDate.slice(
                8,
                10
              )}/${x.eventDate.slice(5, 7)}/${x.eventDate.slice(
                0,
                4
              )} / ${new Date(x.startTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} - ${new Date(x.endTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`,
              value: x.id,
            }))}
            disableClearable
            onChange={handleSelectedPlaylistChange}
            renderInput={(params) => (
              <TextField
                margin="dense"
                fullWidth
                variant="outlined"
                label="Playlist"
                id="selectedPlaylist"
                type="text"
                {...params}
              />
            )}
          />
        ) : null}
        {!state.isLoading && state.playlist && (
          <Typography
            variant="body2"
            color="primary"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              whiteSpace: "pre-line",
            }}
          >
            {state.playlist.map((x) => x.item).join("\n")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {["admin"].includes(state.role) && (
          <Button
            onClick={handleDownload}
            variant="contained"
            disabled={state.isLoading || !state.playlist}
          >
            Download
          </Button>
        )}
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
