import React, { useContext } from "react";
import UserContext from "../contexts/UserContext";
import VenueProfileModal from "./VenueProfileModal";
import DJProfileModal from "./DJProfileModal";

export default function ProfileModal(props) {
  const userContext = useContext(UserContext);

  return userContext.user.venueId ? (
    <VenueProfileModal {...props} />
  ) : (
    <DJProfileModal {...props} />
  );
}
