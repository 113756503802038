import React from "react";
import { Grid, Typography } from "@mui/material";

export default function PrivacyPolicyPage() {
  return (
    <Grid
      container
      p={4}
      spacing={4}
      sx={{ maxWidth: "1600px", paddingTop: "80px" }}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          color="secondary"
          style={{ whiteSpace: "pre-line" }}
        >
          This mobile application or website collects personal data only with
          the knowledge and consent of the users.{"\n"}
          If you would like more information about the data collected and the
          treatment of personal data, you can read this Privacy Policy as well
          as the Legal Notice and the Cookie Policy.{"\n"}
          {"\n"}
          Basic information:{"\n"}
          (These can be placed at the top or bottom){"\n"}
          Company - VIBY ltd{"\n"}
          Address registration - Sofia, Yanko Sakazov str 20{"\n"}
          Correspondence address - Sofia, Yanko Sakazov str 20{"\n"}
          Phone - +359889181955{"\n"}
          E-MAIL - viby.music.project@gmail.com{"\n"}
          {"\n"}
          This privacy policy provides information about the processing of
          personal data of our Users ("Users"), job applicants, social media
          users who interact with us and users of the contact form on our
          website, as provided for in the General Regulation. for data
          protection ("DPO").{"\n"}
          {"\n"}
          3.1 Processed data{"\n"}
          (a) Information provided directly by consumers:{"\n"}• The information
          provided by Users when creating an account on our website or in the
          VIBY ("Platform") application: username and email.{"\n"}• The
          information added by the Users of the Platform in order to be able to
          use the VIBY service; i.e. email. Personal data can be viewed and
          edited by users in their personal profile at any time. Consumer debit
          / credit card details are provided to licensed electronic payment
          service providers who receive, store and process the included data in
          order to facilitate the payment process for consumers required for
          their orders. VIBY does not store Debit / Credit Card Data of Users.
          This information is under no circumstances stored on VIBY's servers.
          The debit / credit card details associated with each user's account
          can be deleted at any time by the user. The service provider will then
          delete the information that will need to be re-entered or selected to
          place new orders through the Platform. The privacy policies of these
          providers may be requested and received by users at any time.{"\n"}•
          Any information that the user may provide to VIBY on his own
          initiative for other purposes. Examples include a photo of the user or
          the billing address in the case of users who have requested to receive
          invoices from VIBY.{"\n"}• The information provided by users to
          resolve any inquiries or complaints about the use of the platform,
          whether through the contact form, email or phone through the customer
          service department will be available to VIBY.{"\n"}• Information on
          incidents involving one of the parties involved in the provision of
          services through the Platform for the purpose of filing insurance
          claims or performing any other actions with the insurance companies
          concluded by VIBY.{"\n"}
          b) Information indirectly provided by consumers:{"\n"}- Each time
          users interact with the Platform, VIBY collects data arising from the
          use of the Platform.{"\n"}- VIBY stores data on the device and the
          Application used by users to access the services. These data are:
          {"\n"}• The IP address used by each user to connect to the Internet
          using their computer or mobile phone.
          {"\n"}• Information about the user's computer or mobile phone, such as
          his Internet connection, browser type, version and operating system
          and device type.{"\n"}• The complete Unified Resource Locator (URL)
          Clickstream, including the date and time of access to the Platform.
          {"\n"}• Information about the orders made by each User, as well as
          feedback and / or comments made about them by such User.{"\n"}• The
          history and preferences of the User's browsing.{"\n"}- VIBY collects
          data on the source from which the VIBY user came if the user found
          himself on the VIBY platform through an external source (such as a
          link from another website or social network).{"\n"}- VIBY will collect
          the received messages in the form used by the user and may use and
          store them to manage current or future incidents if the user connects
          to the VIBY platform via the contact form or the VIBY telephone
          number.{"\n"}- To facilitate browsing by its users and for statistical
          purposes (for more information on the data we collect through cookies
          and how to manage cookie control, see the Cookie Policy - this link
          leads to the explanation of cookies) VIBY uses its own cookies and
          cookies. third parties.{"\n"}- VIBY may only access personal
          information and data if the user has authorized external third parties
          to share personal data collected by them and other information about
          the user. For example, if a user creates an account through their
          Facebook account, Facebook may disclose to us that user's personal
          information that may be found in his / her account. Facebook account
          (such as name, gender, or age), but only if the user's Facebook
          account privacy settings allow sharing.{"\n"}
          If the privacy settings of the user's browser and / or Google account
          allow sharing and the user accesses VIBY through products and services
          offered by Google, Google may send VIBY user's browsing data to the
          platform via links created by Google.{"\n"}
          In accordance with the third party's own privacy policy, the User may
          control VIBY's access to the User's information provided by the
          external third party.{"\n"}- VIBY will collect user data related to
          their location, including the real-time geographical location of their
          computer or mobile device, provided that this is permitted by Users
          {"\n"}
          {"\n"}
          {"\n"}
          Purpose of personal data processing{"\n"}- To use the VIBY platform
          and our services,{"\n"}
          In accordance with the procedure described in the "Terms of Use", VIBY
          uses the data collected by Users to enable them to access and
          communicate with the VIBY platform and to provide the services
          requested by them through their personal account on the VIBY Platform.
          .{"\n"}- To send messages related to the services provided,{"\n"}
          When communicating via e-mail and / or sending Push messages related
          to the operation of the service, VIBY uses the personal data of the
          Users.{"\n"}
          VIBY can send Push messages on the User's mobile phone with
          information related to the status of the requested order. When the
          order is completed, VIBY can send an e-mail to the User summarizing
          the order and its price.
          {"\n"}- To detect and investigate fraud and possible crimes{"\n"}
          VIBY uses the information internally for statistical purposes to
          analyze user behavior and trends, understand how Users use the VIBY
          platform and manage and improve the services offered, including the
          ability to add new, different services to the Platform. VIBY uses the
          information to research and analyze how to improve the services it
          provides to users, as well as to develop and improve the
          characteristics of the service it offers. VIBY can monitor all actions
          that may lead to fraud or the commission of a crime related to the
          means of payment used by consumers.{"\n"}
          In any case, all data will be processed by VIBY for the sole purpose
          of the company to carry out its fraud prevention, detection and
          monitoring activities (VIBY may request users a copy of their ID card,
          as well as certain debit / credit card information used for the
          execution of the order), and they will be kept as long as its
          relationship with the consumer remains in force, and even thereafter
          until the consumer's right to sue or take legal action to pay for the
          products or services ordered through VIBY, has expired. Until the
          incident is resolved, the data related to the used debit / credit card
          will be stored for 120 days thereafter. VIBY reserves the right to
          retain the data provided and to share it with the competent
          authorities in order to carry out an appropriate investigation if
          irregularities are found in its use, which could be considered illegal
          activities. In such cases, VIBY may share the data with the
          authorities on the basis of the legal obligation to prosecute actions
          contrary to applicable law.{"\n"}- For promotional and commercial
          offers (online and offline){"\n"}
          VIBY may use third party technology integrated into its Platform to
          collect data and user preferences and use that data with CRM systems
          and advanced technology for the benefit of users with the prior
          express consent of users. Therefore, the following information will be
          processed through the collected information with the consent of the
          user:{"\n"}• VIBY may evaluate and customize advertising in accordance
          with the preferences of its users and may send emails with promotional
          messages and / or offers related to its service, which may be of
          interest to users. In the event that a VIBY user no longer wishes to
          receive this information and / or commercial communications, he / she
          may at any time choose to “Unsubscribe” from the email through which
          this information was sent, and VIBY will immediately stop sending the
          above. information.{"\n"}• Users may also receive commercial messages
          from third parties related to the Platform, such as Facebook and
          Google as a result of using the VIBY platform. The User may manage the
          receipt of such messages in accordance with the privacy preferences
          set by each User on the specified platforms.{"\n"}
          To unsubscribe from online marketing If they do not wish to receive
          samples of their orders through VIBY, users can use their account
          privacy management center at any time.{"\n"}- For the purposes of
          statistical analysis and service analysis{"\n"}
          To analyze the behavior and trends of users, to understand how Users
          use the VIBY platform and to manage and improve the services offered,
          including the ability to add new, different services to the Platform,
          VIBY can use information related to users and their orders, for
          statistical purposes.
          {"\n"}
          Legal basis of the processing{"\n"}
          The processing of consumer data is done in accordance with the
          following legal bases:{"\n"}• To fulfill the contractual relationship
          after registration of users in the Platform (eg processing of their
          data in connection with the processing of orders, for the execution of
          an order, communication between the Platform, users and the site or
          location, etc.). In order to fulfill our contractual and
          pre-contractual obligations, we may need to process some or all of the
          above categories of data.{"\n"}• Based on and to protect our own
          legitimate interest (such as ensuring the proper functioning and use
          of the Website and the Platform, monitoring to prevent fraud through
          the platform, communication with users, including electronically on
          important issues related to their orders , processing of received
          signals, complaints, requests and other correspondence, etc.). When
          carrying out activities based on a legitimate interest, it may be
          necessary to process some or all of the above categories of data. In
          such cases of personal data processing, we put the fundamental rights
          and freedoms of users first and try not to affect them.{"\n"}• To
          fulfill our legal obligations (for example, when competent state or
          judicial authorities and / or national regulatory authorities request
          information in connection with judicial investigations and / or the
          submission of the necessary actions to protect the interests of VIBY).
          This may require the processing of some or all of the above categories
          of data.{"\n"}• In order to make commercial communications when
          disclosing data to third party users, we may, after explicit consent,
          process personal data provided by users for marketing and promotional
          purposes for voluntary participation in surveys, information from
          which may be used to monitor or improve the performance of our Website
          and Platform, and improve our services. In this regard, with the
          consent of the User, we or our partners - third parties, may send him
          / her any kind of messages through various electronic communication
          channels (email / SMS / web buttons, etc.), which may contain general
          or specific information about our services, promotional and other
          offers. To this end, some or all of the above categories of data may
          need to be processed. In such cases, the data processing we perform is
          within the scope provided for in the relevant consent of the User, who
          may at any time manage and withdraw it in the messages received from
          VIBY.{"\n"}
          {"\n"}
          Recipients of the data{"\n"}
          We will not disclose your personal data to any third party who does
          not act in accordance with our instructions, and no communication will
          include selling, renting, sharing or in any other way disclosing
          personal information to customers for commercial purposes in violation
          of the commitments made in this Privacy Policy. VIBY ensures that all
          trading partners or independent third parties whose services and
          assistance we use to achieve the above purposes for which we process
          personal data are bound by contractually binding promises (data
          processing agreements) to process the information shared with them in
          accordance with with the guidance of VIBY, this Privacy Policy and
          applicable data protection law.{"\n"}
          {"\n"}- When executing an order, the data or part of them to the Users
          can be shared with:{"\n"}• The site or place that performs the task to
          approve and perform the service.{"\n"}• The site or place responsible
          for performing the service, if the User has requested the purchase of
          a service or product. If a User contacts the above-mentioned providers
          directly and provides them with their data directly, VIBY will not be
          responsible for the processing and use of such data by the providers.
          {"\n"}• Customer service services concluded by VIBY in order to warn
          the User of any possible incidents related to the services provided or
          to inquire and analyze why negative feedback has been given about the
          service by dissatisfied Users. VIBY may process and use the data
          provided to manage any incidents that may occur during the provision
          of the services, including through the use of our assistance. Artists
          who have undertaken to process data provided by VIBY in accordance
          with VIBY guidelines, this Privacy Policy and applicable data
          protection law.{"\n"}• The payment platform and the payment service
          providers that partner with VIBY, so that the amounts due by the users
          can be charged to the respective account of the User.{"\n"}•
          Telecommunication service providers when used to send messages about
          orders or order-related incidents.{"\n"}• Providers providing
          satisfaction survey services on behalf of VIBY.{"\n"}- Sharing user
          data with third parties{"\n"}
          VIBY may share certain personal data of Users with the following third
          parties in order to continue to provide the services offered through
          the Platform:{"\n"}• The payment service providers with which VIBY
          partners are selected according to their security measures and in any
          case comply with the security measures provided for in the payment
          services legislation, as they comply with PC1 according to the payment
          card or PCI data protection standard. DSS. When a user enters his
          debit / credit card number on the VIBY platform, it will be stored
          directly by the payment platforms that have concluded a contract with
          VIBY, which will allow the payment to be charged to the user's
          account. VIBY does not store such data under any circumstances.{"\n"}•
          To assess the risk of transactions, VIBY will share user data with
          fraud control service providers.{"\n"}• In order to prevent the misuse
          of user data by third party service providers, VIBY may disclose data
          of third party users who provide data anonymisation services in order
          to anonymise them so that they can be used solely to provide the
          service to consumers. For example, VIBY may assign e-mail to
          third-party users to anonymize them and provide them in this format to
          providers used to perform services agreed by users.{"\n"}• VIBY may
          disclose personal information and account information to its customers
          if it deems such disclosure necessary to comply with the law, enforce
          or enforce the Platform's "Terms of Use" or protect the rights,
          property or safety of VIBY, its users or third parties. Therefore, the
          above includes the exchange of information with companies and
          organizations involved in the field of security, information security
          control and payment transactions, as well as with law enforcement and
          anti-fraud and credit risk mitigation agencies. In accordance with
          statutory procedures, VIBY may, upon request, share information with
          law enforcement and / or legally authorized third parties regarding
          requests for information related to criminal investigations and
          alleged illegal activities.{"\n"}• VIBY may disclose the data of the
          Users of companies located outside the European Economic Area ("EEA")
          provided that it is authorized to do so and take appropriate
          additional safeguards in accordance with the requirements of the ORD,
          such as: a) access to the data will be in accordance with the
          requirements and security systems established by the European
          Commission and the ORD in the countries for which the European
          Commission has recognized an adequate level of personal data
          protection and / or b) by including in the contracts with relevant
          service providers standard contractual clauses approved by the
          European Commission, and / or (c) by selecting service providers that
          have an approved code of conduct within the meaning of the CRPD to
          provide quality customer service and customer call centers and to
          measure customer satisfaction and the provision of administrative
          support services. The implementation of such transfers must also be in
          accordance with the current guidelines and recommendations of the
          European institutions responsible for the control of personal data
          protection with regard to the lawful transfer of personal data to
          third countries.{"\n"}• If a User connects his VIBY account with other
          social media or a third party platform, VIBY may process the
          information provided by Users of such social media or a third party,
          provided that it has been provided to VIBY in accordance with the
          privacy policy of the social media or third party platform in
          question.
          {"\n"}• Provided that the User has given his explicit informed and
          unambiguous consent to the transfer of data and is aware of the
          purpose and identity of the recipient of such transfer, VIBY may
          transfer his personal data to third parties related to VIBY.{"\n"}•
          Users are informed that VIBY will transfer their data to the acquiring
          organizations in order to continue to provide the services that are
          subject to data processing if they own VIBY's assets have changed or
          most of its assets have been acquired by a third party. The new file
          (data) administrator will inform Users of their credentials. VIBY
          states that it will inform Users of the change of file administrator
          if and when this happens and that it will fulfill its obligation to
          provide information to the relevant supervisory authority in the event
          of such circumstances. This processing is carried out in accordance
          with the contract concluded with VIBY and the subsequent transfer of
          rights.{"\n"}• VIBY may provide data to the Users of those insurers
          and insurance brokers with whom it has concluded insurance or other
          agreement for management and processing of claims and losses arising
          from the activities carried out by VIBY and the parties cooperating
          with it for the purposes of the services provided.{"\n"}
          VIBY users' data will not be disclosed to third parties unless: (i)
          this is necessary in order to provide the requested services, if VIBY
          cooperates with third parties for the purposes of these services and
          their provision; (ii) if VIBY has the explicit and unambiguous
          permission of the User; (iii) where requested to do so by a competent
          authority in accordance with its legal functions (in order to
          investigate, prevent or take action in connection with illegal
          activities); or (iv) finally, when required by law.
          {"\n"}
          {"\n"}
          Processing of Job Candidates' data related to VIBY using the forms in
          the VIBY Jobs section{"\n"}
          These clauses will apply to those who contact VIBY through their
          website in order to apply for a vacancy ("Applicants").{"\n"}-
          Administrator{"\n"}
          Administrator{"\n"}
          Name VIBY Ltd{"\n"}
          Address Sofia, Yanko Sakazov 20{"\n"}
          Phone number +359889181955{"\n"}
          Contact email address viby.music.project@gmail.com{"\n"}
          {"\n"}- Purpose{"\n"}
          In order to consider the current or future suitability of the
          Applicant for any of the available positions in VIBY, as well as to
          consider the possibility of establishing a contractual relationship
          with the Applicant, VIBY processes personal data of the Job Applicant.
          In addition, VIBY at its discretion processes the Candidate's data for
          the purpose of conducting interviews it deems necessary for the
          position, checking the Candidate's knowledge, contacting companies for
          which he / she has previously worked, checking the recommendations and
          assessing the skills and abilities of the Applicant as a whole.{"\n"}-
          Processed personal data of the Applicants{"\n"}- Legal basis for
          processing the Candidates' data{"\n"}
          Applicants must be informed of the terms of this Privacy Policy and
          consent to the processing of their data on the recruitment procedures
          carried out by VIBY before submitting their application. In view of
          the fact that such an examination has been requested by the applicants
          themselves, VIBY may also process their data in order to examine the
          possibility of establishing a contractual relationship.{"\n"}- Storage
          of the Candidate's data{"\n"}
          The Applicant's data will be stored during the selection process and
          if the applicant is not selected, for three (3) years after the end of
          this process, and the applicant may exercise any of the rights set out
          in point 7 of this Policy. privacy at all times.{"\n"}
          {"\n"}
          International data transfers{"\n"}
          VIBY may transfer User data outside the European Economic Area when
          selecting service providers. In such cases, VIBY will ensure that,
          prior to the transmission of the data, such service providers comply
          with the minimum security standards established by the European
          Commission and the competent European and national data protection
          authorities and in accordance with the requirements of the DPA, and
          that they always process data in accordance with VIBY instructions.
          VIBY has a contractual relationship with them regarding the respective
          data processing, in which the service providers agree to follow the
          instructions of VIBY and to introduce the necessary security measures
          to protect the data of the Users.{"\n"}
          Storage periods{"\n"}
          During the implementation and maintenance of the contractual
          relationship between the Users and VIBY the user data will be
          preserved; i.e. while they are VIBY users, or while exercising their
          right to restrict the processing of their data.{"\n"}
          VIBY will retain the User's data after he / she cancels his / her
          registration in the Platform, including information on past orders and
          performed services, for the time established in the tax, health,
          criminal and any other applicable legislation for the purposes of
          maintaining files and defending any action or claim to which VIBY may
          be a party, as well as the fulfillment of our legal obligations and
          the exercise of our right of defense in disputes and in all
          proceedings. The period of storage of these data will be 10 years,
          starting from January 1 of the next year of preparation of the
          respective document, according to the requirements of Bulgarian the
          Law on Accounting, when the personal data of the Users are contained
          in accounting documents on paper or technical media, which may be
          legally required by VIBY for the purposes of tax control. During this
          period, VIBY will in any case block the data of the Users and will
          store them in full confidentiality, so that they can be consulted only
          at the request of the User and / or in order to resolve any disputes
          with the User and / or third parties. in connection with the services
          of VIBY and / or who need the assistance of competent authorities,
          institutions and persons who have the legal authority to request the
          provision of personal data and have exercised this authority properly.
          {"\n"}
          In particular, but without prejudice to other applicable legislation,
          the data will be retained after termination by the User, as indicated
          in the table in Annex II.{"\n"}• Exercise of rights{"\n"}
          The form available in the Platform will be available to Users to
          exercise their rights at any time and free of charge. They and others
          who may contact VIBY through our website or the Platform may also
          exercise their personal data rights by sending an application email to
          the following email address: viby.music.project@gmail.com. The email
          in the application must indicate which right he wishes to exercise
          and, where applicable, the identification data registered on the
          Platform or other identification data provided by law, as well as the
          preferred response format, signature subject data, date of submission
          of the application and address for correspondence. If we need
          additional data, in addition to those provided, we will contact the
          User to confirm his identity.{"\n"}
          With regard to VIBY, you can exercise the following rights:{"\n"}•
          Access to your personal data to know which data are processed and the
          processing operations performed with them;{"\n"}• Correction of any
          inaccuracies or incomplete information regarding your personal data;
          {"\n"}• Deletion of your personal data where possible and for any of
          the following reasons: (a) Your personal data is no longer needed for
          the purposes for which it was collected or otherwise processed; (b) if
          you withdraw your consent on which the data processing is based and
          there is no other legal basis for the data processing; (c) if you
          object to the processing of personal data relating to you, including
          for direct marketing purposes, and there are no overriding legal
          grounds for processing; d) if your personal data has been processed
          illegally; (v) Your personal data must be deleted in order to comply
          with a legal obligation under EU law or the law of an EU Member State
          applicable to VIBY.{"\n"}• When the accuracy, legality or necessity of
          data processing is in question, request a restriction on the
          processing of your personal data, in which case we may retain the data
          for the purpose of filing or defending claims.{"\n"}• You have the
          right to withdraw consent at any time if the processing of your
          personal data is based on your consent (as this will not affect the
          processing based on your consent before withdrawal). For this purpose
          you can contact us by sending a request by e-mail to
          viby.music.project@gmail.com, in which case VIBY may ask you to
          confirm your identity{"\n"}• If there is a legal basis for this, you
          may object to the processing of your data, for example you may object
          at any time to the processing of your personal data for direct
          marketing purposes by withdrawing your consent to receive commercial
          communications at any time by the user profile of the Platform, by
          sending an email or by using the link provided for this purpose in any
          commercial communication.{"\n"}• You have the right to request that we
          provide you with your personal data in a machine-readable format for
          transfer to another data controller if your personal data is processed
          automatically by your consent or for the purpose of fulfilling our
          contractual relationship.{"\n"}
          You can contact us if you believe that VIBY violates the data
          protection law, at the e-mail address viby.music.project@gmail.com, by
          telling us about the case so that we can solve the problem as soon as
          possible. In any case, you can report it to the Bulgarian Commission
          for Personal Data Protection and file a claim with the designated body
          for protection of your rights.
          {"\n"}• Security measures{"\n"}
          VIBY has taken the necessary steps, recommended by the European
          Commission and the competent data protection authority, to maintain
          the necessary level of protection of the security and confidentiality
          of the personal data it processes, in accordance with the nature of
          the personal data processed and the processing circumstances. avoid,
          as far as possible and always in accordance with the latest
          technologies, their alteration, loss or unauthorized access or
          processing.{"\n"}• Notices and changes{"\n"}
          This Privacy Policy, Cookie Policy and Terms of Use are subject to and
          change due to the constant development of VIBY's activities. We
          publish all relevant changes on our website, where you can follow
          regularly to stay informed. VIBY will send users notifications of
          significant changes and modifications to such documents by e-mail or
          other method that ensures their receipt.
        </Typography>
      </Grid>
    </Grid>
  );
}
