import { Routes, Route } from "react-router-dom";
import AuthPage from "./pages/LoginPage";
import RequestsPage from "./pages/RequestsPage";
import PlaylistsPage from "./pages/PlaylistsPage";
import PromotionsPage from "./pages/PromotionsPage";
import DeleteProfilePage from "./pages/DeleteProfilePage";
import VenuesPage from "./pages/VenuesPage";
import UsersPage from "./pages/UsersPage";
import DJsPage from "./pages/DJsPage";
import EventsPage from "./pages/EventsPage";
import KaraokePage from "./pages/KaraokePage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<></>} />
      <Route path="/login" exact element={<AuthPage title="Log in" />} />
      <Route path="/register" exact element={<AuthPage title="Sign up" />} />
      <Route
        path="/forgottenpassword"
        exact
        element={<AuthPage title="Forgotten Password" />}
      />
      <Route
        path="/resetpassword"
        exact
        element={<AuthPage title="Reset Password" />}
      />
      <Route
        path="/requests/:eventId"
        exact
        element={
          <PrivateRoute>
            <RequestsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/playlists/:eventId"
        exact
        element={
          <PrivateRoute>
            <PlaylistsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/promotions"
        exact
        element={
          <PrivateRoute>
            <PromotionsPage />
          </PrivateRoute>
        }
      />
      <Route path="/deleteprofile" exact element={<DeleteProfilePage />} />
      <Route
        path="/venues"
        exact
        element={
          <PrivateRoute>
            <VenuesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        exact
        element={
          <PrivateRoute>
            <UsersPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/djs"
        exact
        element={
          <PrivateRoute>
            <DJsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/events"
        exact
        element={
          <PrivateRoute>
            <EventsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/karaoke"
        exact
        element={
          <PrivateRoute>
            <KaraokePage />
          </PrivateRoute>
        }
      />
      <Route path="/termsofuse" exact element={<TermsOfUsePage />} />
      <Route path="/privacypolicy" exact element={<PrivacyPolicyPage />} />
    </Routes>
  );
}

export default App;
