import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { updateVenue } from "../api";
import readStreamAsBase64 from "../utils/readStreamAsBase64";
import { AddPhotoAlternate } from "@mui/icons-material";
import UserContext from "../contexts/UserContext";
import resizeFile from "../utils/resizeFile";
import CustomMap from "./CustomMap";

const genres = [
  "Dance & Electronic",
  "Pop",
  "Rap/Hip-Hop",
  "Rock",
  "Alternative Rock",
  "Jazz",
  "Pop Folk",
  "Lounge music",
];

export default function VenueProfileModal(props) {
  const [state, setState] = useState({
    genres: [],
  });

  const userContext = useContext(UserContext);

  const handleChangeLogo = async (e) => {
    const logo = await resizeFile(e.target.files[0]);
    setState((s) => ({
      ...s,
      logo,
      logoFile: URL.createObjectURL(e.target.files[0]),
      logoHasChanged: true,
    }));
  };

  // const handleChangePhoto = async (e) => {
  //   const photo = await resizeFile(e.target.files[0]);
  //   setState((s) => ({
  //     ...s,
  //     photo,
  //     photoFile: URL.createObjectURL(e.target.files[0]),
  //     photoHasChanged: true,
  //   }));
  // };

  const nameInputRef = useRef();
  const facebookInputRef = useRef();
  const instagramInputRef = useRef();
  const websiteInputRef = useRef();
  const cityInputRef = useRef();
  const addressInputRef = useRef();

  const handleConfirm = async () => {
    if (!nameInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          name: "Name is required.",
        },
      }));
      return;
    }

    setState((s) => ({
      ...s,
      isLoading: true,
      errors: undefined,
    }));
    const base64Logo = state.logoHasChanged
      ? await readStreamAsBase64(state.logo)
      : undefined;
    // const base64Photo = state.photoHasChanged
    //   ? await readStreamAsBase64(state.photo)
    //   : undefined;
    const body = {
      id: userContext.user.venueId,
      name: nameInputRef.current.value,
      facebook: facebookInputRef.current.value,
      instagram: instagramInputRef.current.value,
      website: websiteInputRef.current.value,
      city: cityInputRef.current.value,
      address: addressInputRef.current.value,
      shortDescription: genres
        .filter((x) => state.genres.includes(x))
        .join(" · "),
      coords: state.coords,
      base64Logo,
      // base64Photo,
    };
    await updateVenue(body);
    userContext.setUser({
      ...userContext.user,
      ...body,
      avatar: { uri: state.logoFile },
    });
    props.handleClose();
  };

  useEffect(() => {
    if (!userContext.user.venueId) return;
    (async () => {
      // const [{ image }] = await getVenueImages([
      //   { id: userContext.user.venueId },
      // ]);
      setState((s) => ({
        ...s,
        ...userContext.user,
        logoFile: userContext.user.avatar?.uri,
        // photoFile: image?.uri,
        genres: userContext.user.shortDescription?.split(" · ") || [],
      }));
    })();
  }, [userContext.user]);

  const handleGenresChange = (genre) => (e) => {
    if (e.target.checked) {
      setState((s) => ({
        ...s,
        genres: [...s.genres, genre],
      }));
    } else {
      setState((s) => ({
        ...s,
        genres: s.genres.filter((x) => x !== genre),
      }));
    }
  };

  const handleCoordsChange = (coords) => {
    setState((s) => ({
      ...s,
      coords,
    }));
  };

  return state.id ? (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={nameInputRef}
          defaultValue={userContext.user.name}
          required
          error={!!state.errors?.name}
          helperText={state.errors?.name}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          label="Logo"
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: "div",
          }}
          inputProps={{
            children: (
              <>
                {state.logoFile && (
                  <img
                    alt={state.name}
                    src={state.logoFile}
                    height="200"
                    style={{ borderRadius: "8px" }}
                  />
                )}
                <div>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: "4px" }}
                  >
                    <AddPhotoAlternate
                      sx={{ fontSize: "20px", marginRight: "4px" }}
                    />
                    {state.logoFile ? "Change" : "Add"}
                    <input type="file" hidden onChange={handleChangeLogo} />
                  </Button>
                </div>
              </>
            ),
          }}
        />
        {/* <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          label="Venue Photo"
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: "div",
          }}
          inputProps={{
            children: (
              <>
                {state.photoFile && (
                  <img
                    alt={state.name}
                    src={state.photoFile}
                    height="200"
                    style={{ borderRadius: "8px" }}
                  />
                )}
                <div>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: "4px" }}
                  >
                    <AddPhotoAlternate
                      sx={{ fontSize: "20px", marginRight: "4px" }}
                    />
                    {state.photoFile ? "Change" : "Add"}
                    <input type="file" hidden onChange={handleChangePhoto} />
                  </Button>
                </div>
              </>
            ),
          }}
        /> */}
        <TextField
          autoComplete="off"
          margin="dense"
          id="city"
          label="City"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={cityInputRef}
          defaultValue={userContext.user.city}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="address"
          label="Address"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={addressInputRef}
          defaultValue={userContext.user.address}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="facebook"
          label="Facebook Link"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={facebookInputRef}
          defaultValue={userContext.user.facebook}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="instagram"
          label="Instagram Link"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={instagramInputRef}
          defaultValue={userContext.user.instagram}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="website"
          label="Website Link"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={websiteInputRef}
          defaultValue={userContext.user.website}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          label="Genres"
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: "div",
          }}
          inputProps={{
            children: (
              <>
                {genres.map((genre) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.genres.includes(genre)}
                        onChange={handleGenresChange(genre)}
                      />
                    }
                    label={genre}
                    key={genre}
                  />
                ))}
              </>
            ),
          }}
        />
        <CustomMap
          zoom={state.coords ? 15 : 10}
          center={
            state.coords
              ? {
                  lat: state.coords.latitude,
                  lng: state.coords.longitude,
                }
              : {
                  lat: 42.6977,
                  lng: 23.3219,
                }
          }
          handleCoordsChange={handleCoordsChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
