import React, { useState, useRef } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export default function KaraokeModal(props) {
  const nameInputRef = useRef();
  const artistTrackInputRef = useRef();

  const [state, setState] = useState({});

  const handleConfirm = async () => {
    if (!nameInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          name: "Name is required.",
        },
      }));
      return;
    }

    if (!artistTrackInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          artistTrack: "Artist - Track is required.",
        },
      }));
      return;
    }

    setState((s) => ({
      ...s,
      isLoading: true,
      errors: undefined,
    }));
    const body = {
      userName: nameInputRef.current.value,
      item: artistTrackInputRef.current.value,
      isSuggestion: true,
      isMessage: false,
      isKaraoke: true,
      isSports: false,
      isTip: false,
      eventId: props.eventId,
      venueId: props.venueId,
      playlistId: props.playlistId,
    };
    await props.handleConfirm(body);
    props.handleClose();
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>New Request</DialogTitle>
      <DialogContent>
        <TextField
          autoComplete="off"
          margin="dense"
          id="artistTrack"
          label="Artist - Track"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={artistTrackInputRef}
          required
          error={!!state.errors?.artistTrack}
          helperText={state.errors?.artistTrack}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="Your Name"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={nameInputRef}
          required
          error={!!state.errors?.name}
          helperText={state.errors?.name}
        />
        {/* <div style={{ marginTop: "8px" }}>
          <div>
            <FontAwesomeIcon
              icon={faClock}
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />
            <Typography variant="body">
              Your request will wait for the DJ's approval.
            </Typography>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCheck}
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />
            <Typography variant="body">
              It will appear on the feed when it is approved.
            </Typography>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faX}
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />
            <Typography variant="body">
              If it is not approved, talk to the DJ.
            </Typography>
          </div>
        </div> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
