import React, { useContext, useEffect, useState } from "react";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import ProfileModal from "../components/ProfileModal";

const routes = [
  ["/events", "Events"],
  ["/playlists", "Events"],
  ["/requests", "Events"],
  ["/promotions", "Promotions"],
  ["/venues", "Venues"],
  ["/users", "Users"],
  ["/djs", "DJs"],
  ["/login", "Log In"],
  ["/register", "Sign Up"],
];

const ResponsiveAppBar = () => {
  const [state, setState] = useState({
    pages: [],
    settings: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  const setActivePage = (location) => {
    const activeRoute = routes.find(([route]) =>
      location.pathname.startsWith(route)
    );
    const activePage = activeRoute && activeRoute[1];
    setState((s) => ({
      ...s,
      activePage,
    }));
  };

  useEffect(() => {
    setActivePage(location);
  }, [location]);

  const userContext = useContext(UserContext);

  const handleOpenProfileModal = () => {
    setState((s) => ({
      ...s,
      open: true,
    }));
  };

  const handleClose = () => {
    setState((s) => ({
      ...s,
      open: false,
    }));
  };

  useEffect(() => {
    const pages = [];
    const settings = [];
    if (userContext.user) {
      pages.push(["Events", () => navigate("/events")]);
      pages.push(["Venues", () => navigate("/venues")]);
      if (["admin"].includes(userContext.user.role)) {
        pages.push(["Users", () => navigate("/users")]);
        pages.push(["DJs", () => navigate("/djs")]);
      }
      if (["karaoke"].includes(userContext.user.role)) {
        pages.push(["Karaoke", () => navigate("/karaoke")]);
      }
      if (["dj", "venue", "admin"].includes(userContext.user.role)) {
        settings.push(["Profile", handleOpenProfileModal]);
      }
      settings.push([
        "Log out",
        () => {
          userContext.unsetUser();
          navigate("/");
        },
      ]);
    } else {
      pages.push(["Log In", () => navigate("/login")]);
      pages.push(["Sign Up", () => navigate("/register")]);
    }
    setState((s) => ({
      ...s,
      pages,
      settings,
    }));

    if (!userContext.user?.userId) {
      handleCloseNavMenu();
      handleCloseUserMenu();
      setState((s) => ({
        ...s,
        user: undefined,
      }));
    }
  }, [userContext.user, navigate, userContext]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleLogoClick = () => navigate("/");

  return (
    <>
      {/* <AppBar
        position="static"
        sx={{ backgroundColor: "transparent", position: "fixed" }}
      > */}
      <Container
        sx={{
          backgroundColor: "rgba(22, 11, 36, 0.5)",
          position: "fixed",
          zIndex: 1000,
          width: "100%",
          margin: "auto",
          padding: 0,
          maxWidth: "none!important",
        }}
      >
        <Toolbar disableGutters>
          {/* <Button onClick={handleLogoClick}>
              <img src="logo.png" alt="VIBY Music" style={{ height: 50 }} />
            </Button> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="secondary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: "block",
              }}
              onClick={handleCloseNavMenu}
            >
              {state.pages.map(([name, handleClick]) => (
                <MenuItem key={name} onClick={handleClick}>
                  <Typography textAlign="center">{name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            {state.pages.map(([name, handleClick]) => (
              <Button
                key={name}
                onClick={handleClick}
                sx={{ mx: 2, my: 2, display: "block" }}
                color="secondary"
                variant={state.activePage === name ? "contained" : "outlined"}
              >
                {name}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: "flex" }}>
            {userContext.user ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu}>
                    <Avatar
                      alt={userContext.user.name || userContext.user.username}
                      src={userContext.user.avatar?.uri}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                >
                  {state.settings.map(([name, handleClick]) => (
                    <MenuItem key={name} onClick={handleClick}>
                      <Typography textAlign="center">{name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : null}
          </Box>
        </Toolbar>
      </Container>
      {/* </AppBar> */}
      {state.open && <ProfileModal handleClose={handleClose} />}
    </>
  );
};
export default ResponsiveAppBar;
