import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Button,
  CardContent,
  CardActions,
  Grid,
  Card,
  CardMedia,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getEventsByVenueId,
  getEventsByDj,
  getEvents,
  createEvent,
  editEvent,
  deleteEvent,
  sendPostEventEmail,
} from "../api";
import { Delete, Edit, Email } from "@mui/icons-material";
import EventModal from "../components/EventModal";
import DeleteModal from "../components/DeleteModal";
import PostEventEmailModal from "../components/PostEventEmailModal";
import Loader from "../components/common/Loader";

const limit = 12;

export default function EventsPage() {
  const navigate = useNavigate();

  const [state, setState] = useState({ djs: [], data: [], hasMore: true });

  const handleCreate = () => {
    setState((s) => ({
      ...s,
      open: true,
      event: undefined,
      handleConfirm: async (body) => {
        const event = await createEvent(body);
        setState((s) => ({
          ...s,
          data: [...s.data, event].sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          ),
        }));
      },
    }));
  };

  const handleEdit = (item) => () => {
    setState((s) => ({
      ...s,
      open: true,
      event: item,
      handleConfirm: async (body) => {
        const event = await editEvent(body);
        s.data.splice(
          s.data.indexOf(s.data.find((x) => x.id === event.id)),
          1,
          event
        );
      },
    }));
  };

  const handleClose = () => {
    setState((s) => ({
      ...s,
      open: false,
      event: undefined,
      handleConfirm: undefined,
    }));
  };

  const handleDelete = (item) => async () => {
    setState((s) => ({
      ...s,
      delete: true,
      deleteName: item.name,
      handleDeleteConfirm: async () => {
        await deleteEvent(item);
        setState((s) => ({
          ...s,
          data: [...s.data.filter((x) => x.id !== item.id)],
        }));
      },
    }));
  };

  const handleDeleteClose = () => {
    setState((s) => ({
      ...s,
      delete: false,
      deleteName: undefined,
      handleDeleteConfirm: undefined,
    }));
  };

  const handleSendPostEventEmail = (item) => async () => {
    setState((s) => ({
      ...s,
      sendPostEventEmail: true,
      event: item,
      handleSendPostEventEmailConfirm: async (body) => {
        await sendPostEventEmail(body);
        s.data.find((x) => x.id === item.id).postEventEmail = true;
      },
    }));
  };

  const handleSendPostEventEmailClose = () => {
    setState((s) => ({
      ...s,
      sendPostEventEmail: false,
      event: undefined,
      handleSendPostEventEmailConfirm: undefined,
    }));
  };

  const fetchData = useCallback(() => {
    (async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 2.5 <
          document.documentElement.offsetHeight ||
        state.isLoading ||
        !state.hasMore
      ) {
        return;
      }

      setState((s) => ({
        ...s,
        isLoading: true,
        error: null,
      }));

      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
          const data =
            user.role === "dj"
              ? await getEventsByDj(user.userId)
              : user.role === "venue"
              ? await getEventsByVenueId(user.venueId)
              : await getEvents({
                  id: state.data.slice(-1)[0]?.lastEvaluatedKey?.id,
                  date: state.data.slice(-1)[0]?.lastEvaluatedKey?.date,
                  limit,
                });
          const now = new Date().getTime();
          setState((s) => ({
            ...s,
            data: [...s.data, ...data],
            venueId: user.venueId,
            role: user.role,
            now,
            hasMore:
              !["dj", "venue"].includes(user.role) && data.length === limit,
          }));
        }
      } catch (error) {
        setState((s) => ({
          ...s,
          error,
        }));
      } finally {
        setState((s) => ({
          ...s,
          isLoading: false,
        }));
      }
    })();
  }, [state.data, state.hasMore, state.isLoading]);

  useEffect(() => {
    fetchData();

    window.addEventListener("scroll", fetchData);

    return () => window.removeEventListener("scroll", fetchData);
  }, [fetchData]);

  return (
    <>
      <Grid
        container
        p={4}
        spacing={4}
        sx={{ maxWidth: "1600px", paddingTop: "80px" }}
        justifyContent="center"
      >
        {["venue", "admin"].includes(state.role) && (
          <Grid item xs={12}>
            <Button type="submit" onClick={handleCreate} variant="contained">
              Create Event
            </Button>
          </Grid>
        )}
        {state.data &&
          state.data.map((item) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              key={item.id}
              sx={{ background: "transparent" }}
            >
              <Card variant="outlined" sx={{ backgroundColor: "#4b445b" }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={item.image.uri}
                  alt={item.name}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      height: "64px",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body2" color="secondary" mb={1}>
                    {item.venueName}
                  </Typography>
                  <Typography variant="body2" color="secondary" mb={1}>
                    {new Date(item.date).toLocaleDateString([], {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      height: "40px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  {["venue", "dj", "admin"].includes(state.role) && (
                    <Button
                      type="button"
                      onClick={() => navigate(`/playlists/${item.id}`)}
                      variant="contained"
                      color="secondary"
                    >
                      Playlists
                    </Button>
                  )}
                  {["venue", "dj", "admin"].includes(state.role) && (
                    <Button
                      type="button"
                      onClick={() => navigate(`/requests/${item.id}`)}
                      variant="contained"
                      color="secondary"
                      sx={{ marginRight: "auto" }}
                    >
                      Requests
                    </Button>
                  )}
                  {["admin"].includes(state.role) &&
                    state.now > item.endsAt &&
                    !item.postEventEmail && (
                      <IconButton
                        aria-label="sendPostEventEmail"
                        onClick={handleSendPostEventEmail(item)}
                        color="secondary"
                      >
                        <Email />
                      </IconButton>
                    )}
                  {["venue", "admin"].includes(state.role) && (
                    <IconButton
                      aria-label="edit"
                      onClick={handleEdit(item)}
                      color="secondary"
                      sx={{ marginLeft: "auto" }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                  {["venue", "admin"].includes(state.role) && (
                    <IconButton
                      aria-label="delete"
                      onClick={handleDelete(item)}
                      color="secondary"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      {state.isLoading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
      {state.sendPostEventEmail && (
        <PostEventEmailModal
          event={state.event}
          handleConfirm={state.handleSendPostEventEmailConfirm}
          handleClose={handleSendPostEventEmailClose}
        />
      )}
      {state.open && (
        <EventModal
          venueId={state.venueId}
          event={state.event}
          handleConfirm={state.handleConfirm}
          handleClose={handleClose}
        />
      )}
      {state.delete && (
        <DeleteModal
          name={state.deleteName}
          handleConfirm={state.handleDeleteConfirm}
          handleClose={handleDeleteClose}
        />
      )}
    </>
  );
}
