import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { updateProfile } from "../api";
import readStreamAsBase64 from "../utils/readStreamAsBase64";
import { AddPhotoAlternate } from "@mui/icons-material";
import UserContext from "../contexts/UserContext";
import resizeFile from "../utils/resizeFile";

export default function DJProfileModal(props) {
  const [state, setState] = useState({});

  const userContext = useContext(UserContext);

  const handleChangeAvatar = async (e) => {
    const avatar = await resizeFile(e.target.files[0]);
    setState((s) => ({
      ...s,
      avatar,
      avatarFile: URL.createObjectURL(e.target.files[0]),
      avatarHasChanged: true,
    }));
  };

  const nameInputRef = useRef();
  const facebookInputRef = useRef();
  const instagramInputRef = useRef();

  const handleConfirm = async () => {
    if (!nameInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          name: "Name is required.",
        },
      }));
      return;
    }

    setState((s) => ({
      ...s,
      isLoading: true,
      errors: undefined,
    }));
    const base64Avatar = state.avatarHasChanged
      ? await readStreamAsBase64(state.avatar)
      : undefined;
    const body = {
      userId: state.userId,
      name: nameInputRef.current.value,
      facebook: facebookInputRef.current.value,
      instagram: instagramInputRef.current.value,
      base64Avatar,
    };
    await updateProfile(body);
    userContext.setUser({
      ...userContext.user,
      ...body,
      avatar: { uri: state.avatarFile },
    });
    props.handleClose();
  };

  useEffect(() => {
    if (!userContext.user.userId) return;
    (async () => {
      setState((s) => ({
        ...s,
        ...userContext.user,
        avatarFile: userContext.user.avatar?.uri,
      }));
    })();
  }, [userContext.user]);

  return state.username ? (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={nameInputRef}
          defaultValue={state.name}
          required
          error={!!state.errors?.name}
          helperText={state.errors?.name}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          label="Avatar"
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: "div",
          }}
          inputProps={{
            children: (
              <>
                {state.avatarFile && (
                  <img
                    alt={state.name}
                    src={state.avatarFile}
                    height="200"
                    style={{ borderRadius: "8px" }}
                  />
                )}
                <div>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: "4px" }}
                  >
                    <AddPhotoAlternate
                      sx={{ fontSize: "20px", marginRight: "4px" }}
                    />
                    {state.avatarFile ? "Change" : "Add"}
                    <input type="file" hidden onChange={handleChangeAvatar} />
                  </Button>
                </div>
              </>
            ),
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="facebook"
          label="Facebook Link"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={facebookInputRef}
          defaultValue={state.facebook}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="instagram"
          label="Instagram Link"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={instagramInputRef}
          defaultValue={state.instagram}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
