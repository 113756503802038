import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { getVenues } from "../api";
import readStreamAsBase64 from "../utils/readStreamAsBase64";
import { AddPhotoAlternate } from "@mui/icons-material";
import resizeFile from "../utils/resizeFile";
import UserContext from "../contexts/UserContext";

const roles = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "DJ",
    value: "dj",
  },
  {
    label: "Venue",
    value: "venue",
  },
  {
    label: "User",
    value: undefined,
  },
];

export default function UserModal(props) {
  const nameInputRef = useRef();
  const typeInputRef = useRef();
  const emailInputRef = useRef();
  const usernameInputRef = useRef();
  const facebookInputRef = useRef();
  const instagramInputRef = useRef();

  const [state, setState] = useState({});

  const userContext = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (userContext.user.role === "admin") {
        const venues = await getVenues();
        setState((s) => {
          const venueItem = venues.find((x) => x.id === s.venueId);
          const venue = venueItem && {
            label: venueItem.name,
            value: venueItem.id,
          };
          return {
            ...s,
            venues,
            venue,
          };
        });
      }
    })();
  }, [userContext.user.role]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      ...props.user,
      avatarFile: props.user?.avatar?.uri,
    }));
  }, [props.user, props.venueId]);

  const handleVenueChange = (e, value) => {
    setState((s) => {
      const venueItem = s.venues.find((x) => x.id === value.value);
      const venue = venueItem && {
        label: venueItem.name,
        value: venueItem.id,
      };
      return {
        ...s,
        venue,
      };
    });
  };

  const handleRoleChange = (e, value) => {
    setState((s) => {
      return {
        ...s,
        role: value.value,
        venue: undefined,
      };
    });
  };

  const handleChangeAvatar = async (e) => {
    const avatar = await resizeFile(e.target.files[0]);
    setState((s) => ({
      ...s,
      avatar,
      avatarFile: URL.createObjectURL(e.target.files[0]),
      avatarHasChanged: true,
    }));
  };

  const handleConfirm = async () => {
    if (!nameInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          name: "Name is required.",
        },
      }));
      return;
    }

    if (!usernameInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          name: "Username is required.",
        },
      }));
      return;
    }

    if (state.role === "venue" && !(state.venue?.value || state.venueId)) {
      setState((s) => ({
        ...s,
        errors: {
          venueId: "Venue is required.",
        },
      }));
      return;
    }

    setState((s) => ({
      ...s,
      isLoading: true,
      errors: undefined,
    }));
    const base64Avatar = state.avatarHasChanged
      ? await readStreamAsBase64(state.avatar)
      : undefined;
    const body = {
      id: state.id,
      role: state.role || "user",
      venueId: state.venue?.value || state.venueId,
      name: nameInputRef.current.value,
      facebook: facebookInputRef.current.value,
      instagram: instagramInputRef.current.value,
      base64Avatar,
    };
    await props.handleConfirm(body);
    props.handleClose();
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Autocomplete
          disablePortal
          id="role-dropdown"
          options={roles}
          value={roles.find((x) => x.value === state.role)}
          disableClearable
          onChange={handleRoleChange}
          renderInput={(params) => (
            <TextField
              margin="dense"
              fullWidth
              variant="outlined"
              label="Role"
              id="role"
              type="text"
              {...params}
            />
          )}
        />
        {state.role === "venue" && state.venues?.length ? (
          <Autocomplete
            disablePortal
            id="venueId-dropdown"
            options={state.venues.map((x) => ({
              label: x.name,
              value: x.id,
            }))}
            value={state.venue}
            disableClearable
            onChange={handleVenueChange}
            renderInput={(params) => (
              <TextField
                margin="dense"
                fullWidth
                variant="outlined"
                label="Venue"
                id="venueId"
                type="text"
                required
                error={!!state.errors?.venueId}
                helperText={state.errors?.venueId}
                {...params}
              />
            )}
          />
        ) : null}
        <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          label="Avatar"
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: "div",
          }}
          inputProps={{
            children: (
              <>
                {state.avatarFile && (
                  <img
                    alt={state.username}
                    src={state.avatarFile}
                    height="200"
                    style={{ borderRadius: "8px" }}
                  />
                )}
                <div>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: "4px" }}
                  >
                    <AddPhotoAlternate
                      sx={{ fontSize: "20px", marginRight: "4px" }}
                    />
                    {state.avatarFile ? "Change" : "Add"}
                    <input type="file" hidden onChange={handleChangeAvatar} />
                  </Button>
                </div>
              </>
            ),
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="type"
          label="Type"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={typeInputRef}
          defaultValue={state.type}
          disabled
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="email"
          label="Email"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={emailInputRef}
          defaultValue={state.email}
          disabled
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="username"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={usernameInputRef}
          defaultValue={state.username}
          disabled
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={nameInputRef}
          defaultValue={state.name}
          required
          error={!!state.errors?.name}
          helperText={state.errors?.name}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="facebook"
          label="Facebook"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={facebookInputRef}
          defaultValue={state.facebook}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="instagram"
          label="Instagram"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={instagramInputRef}
          defaultValue={state.instagram}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
