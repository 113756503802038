import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { deleteProfile } from "../api";
import DeleteModal from "../components/DeleteModal";
import UserContext from "../contexts/UserContext";

export default function DeleteProfilePage() {
  const navigate = useNavigate();

  const [state, setState] = useState({});

  const userContext = useContext(UserContext);

  const handleDelete = async () => {
    setState((s) => ({
      ...s,
      delete: true,
      deleteName: "your profile",
      handleDeleteConfirm: async () => {
        userContext.unsetUser();
        navigate("/");
        deleteProfile();
      },
    }));
  };

  const handleDeleteClose = () => {
    setState((s) => ({
      ...s,
      delete: false,
      deleteName: undefined,
      handleDeleteConfirm: undefined,
    }));
  };
  return (
    <Grid
      container
      p={4}
      spacing={4}
      sx={{ maxWidth: "1600px", paddingTop: "80px" }}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          color="secondary"
          style={{ whiteSpace: "pre-line" }}
        >
          Are you sure you want to delete your VIBY Music application profile?
          This action is irreversible!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" onClick={handleDelete} variant="contained">
          Delete Profile
        </Button>
      </Grid>
      {state.delete && (
        <DeleteModal
          name={state.deleteName}
          handleConfirm={state.handleDeleteConfirm}
          handleClose={handleDeleteClose}
        />
      )}
    </Grid>
  );
}
