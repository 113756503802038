import config from "../config";

const headers = {
  "content-type": "application/json",
  "x-app-id": "1be5353c-723e-48fb-aef3-d095ece2aa32",
  "x-app-version": 270,
  pragma: "no-cache",
  "cache-control": "no-cache",
};

const fetchFromApiOnce = async (url, options = {}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.accessToken) {
    headers.authorization = user.accessToken;
  }
  return fetch(`${config.apiUrl}${url}`, {
    ...options,
    headers,
  });
};

const fetchFromApi = async (url, options = {}) => {
  let result = await fetchFromApiOnce(url, options);
  // if (result.status === 401) {
  // const userId = localStorage.getItem("userId");
  // const { accessToken, refreshToken } = await refreshToken(userId);
  // await SecureStore.setItemAsync("accessToken", accessToken);
  // await SecureStore.setItemAsync("refreshToken", refreshToken);
  // result = await fetchFromApiOnce(url, options);
  // }
  return result;
};

export const login = (body) =>
  fetchFromApi(`/login`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const loginWithFb = (body) =>
  fetchFromApi(`/loginWithFb`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const loginWithApple = (body) =>
  fetchFromApi(`/loginWithApple`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const checkUser = (body) =>
  fetchFromApi(`/checkUser`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const signup = (body) =>
  fetchFromApi(`/signup`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const getFeed = (eventId) =>
  fetchFromApi(`?eventId=${eventId}`).then((result) => result.json());

export const getVenue = (id) =>
  fetchFromApi(`/venue?id=${id}`).then((result) => result.json());

export const getVenues = () =>
  fetchFromApi(`/venues`).then((result) => result.json());

export const getVenuesWithLogos = () =>
  fetchFromApi(`/venues?withLogos=true`).then((result) => result.json());

export const getVenueImages = (venues) =>
  fetchFromApi(
    `/venueImages?venueIds[]=${venues.map((x) => x.id).join("&venueIds[]=")}`
  )
    .then((result) => result.json())
    .then((json) =>
      venues.map((x) => ({
        ...x,
        image: json.find((y) => y.id === x.id).image,
      }))
    );

export const getVenueLogos = (venues) =>
  fetchFromApi(
    `/venueLogos?venueIds[]=${venues.map((x) => x.id).join("&venueIds[]=")}`
  )
    .then((result) => result.json())
    .then((json) =>
      venues.map((x) => ({
        ...x,
        logo: json.find((y) => y.id === x.id).logo,
      }))
    );

export const createVenue = (body) =>
  fetchFromApi(`/venues`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const updateVenue = (body) =>
  fetchFromApi(`/venues`, {
    method: "put",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const deleteVenue = (body) =>
  fetchFromApi(`/venues`, {
    method: "delete",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const getPlaylists = (eventId) =>
  fetchFromApi(`/playlists?eventId=${eventId}`).then((result) => result.json());

export const previewPlaylist = (body) =>
  fetchFromApi(`/previewPlaylist`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const createPlaylist = (body) =>
  fetchFromApi(`/playlists`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const editPlaylist = (body) =>
  fetchFromApi(`/playlists`, {
    method: "put",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const deletePlaylist = (body) =>
  fetchFromApi(`/playlists`, {
    method: "delete",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const getDjs = () =>
  fetchFromApi(`/djs`).then((result) => result.json());

export const getProfile = (userId) =>
  fetchFromApi(`/profile?userId=${userId}`).then((result) => result.json());

export const updateProfile = (body) =>
  fetchFromApi(`/profile`, {
    method: "post",
    body: JSON.stringify(body),
  });

export const getPromotions = () =>
  fetchFromApi(`/promotions`).then((result) => result.json());

export const getPromotionsByVenueId = (venueId) =>
  fetchFromApi(`/promotions?venueId=${venueId}`).then((result) =>
    result.json()
  );

export const createPromotion = (body) =>
  fetchFromApi(`/promotions`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const editPromotion = (body) =>
  fetchFromApi(`/promotions`, {
    method: "put",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const deletePromotion = (body) =>
  fetchFromApi(`/promotions`, {
    method: "delete",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const getEvents = ({
  fromDate = "",
  toDate = "",
  id = "",
  date = "",
  dj = "",
  venueId = "",
  limit,
}) =>
  fetchFromApi(
    `/events?fromDate=${fromDate}&toDate=${toDate}&id=${id}&date=${date}&dj=${dj}&venueId=${venueId}&limit=${limit}`
  ).then((result) => result.json());

export const getEventsByVenueId = (venueId) =>
  fetchFromApi(`/events?venueId=${venueId}`).then((result) => result.json());

export const getEventsByDj = (dj) =>
  fetchFromApi(`/events?dj=${dj}`).then((result) => result.json());

export const createEvent = (body) =>
  fetchFromApi(`/events`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const editEvent = (body) =>
  fetchFromApi(`/events`, {
    method: "put",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const deleteEvent = (body) =>
  fetchFromApi(`/events`, {
    method: "delete",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const completeOrder = (body) => {
  fetchFromApi(`/completed`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());
};

export const approveOrder = (body) => {
  fetchFromApi(`/approved`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());
};

export const rejectOrder = (body) => {
  fetchFromApi(`/rejected`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());
};

export const hide = (body) => {
  fetchFromApi(`/hide`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());
};

export const unhide = (body) => {
  fetchFromApi(`/unhide`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());
};

export const checkToken = () => {
  fetchFromApi(`/checkToken`, {
    method: "post",
  });
};

export const getUser = (usernameOrEmail) =>
  fetchFromApi(`/user?usernameOrEmail=${usernameOrEmail}`).then((result) =>
    result.json()
  );

export const getUsers = (role = "") =>
  fetchFromApi(`/users?role=${role}`).then((result) => result.json());

export const updateUser = (body) =>
  fetchFromApi(`/users`, {
    method: "put",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const sendPostEventEmail = (body) =>
  fetchFromApi(`/postEventEmail`, {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => result.json());

export const getUserPlaylists = ({ eventId, venueId }) =>
  fetchFromApi(
    `/userPlaylists?eventId=${eventId}&venueId=${venueId}`
  ).then((result) => result.json());

export const createOrder = (body) =>
  fetchFromApi("", {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const forgottenPassword = (body) =>
  fetchFromApi("/forgottenPassword", {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const resetPassword = (body) =>
  fetchFromApi("/resetPassword", {
    method: "post",
    body: JSON.stringify(body),
  }).then((result) => {
    if (result.status === 400)
      return result.text().then((text) => Promise.reject(text));
    return result.json();
  });

export const deleteProfile = () =>
  fetchFromApi(`/profiles`, {
    method: "delete",
  });
