import React, { useEffect, useState, useCallback } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { getFeed, getPlaylists, hide, unhide } from "../api";
import config from "../config";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RequestTable from "../components/RequestTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../components/common/Loader";

const tableStatuses = ["initiated", "pending", "confirmed", "rejected", "tip"];

export default function RequestsPage() {
  const { eventId } = useParams();

  const [state, setState] = useState({});
  const [isSports, setIsSports] = useState(false);
  const [isKaraoke, setIsKaraoke] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getFeed(eventId);
      const playlistData = await getPlaylists(eventId);
      let hidden = [];
      playlistData.playlists.forEach(
        (playlist) => (hidden = hidden.concat(playlist.hidden))
      );

      setIsSports(playlistData.isSports);
      setIsKaraoke(playlistData.isKaraoke);
      setState((s) => ({
        ...s,
        playlists: playlistData.playlists,
        hidden,
        data: data.map((order) => ({
          order,
        })),
      }));
      console.log("data", data);
    })();
  }, [eventId]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const socketByEventId = socketIOClient(config.wsUrl, {
        query: {
          eventId,
        },
      });

      socketByEventId.on("order_initiated", addOrUpdateOrder);
      socketByEventId.on("order_created", addOrUpdateOrder);
      socketByEventId.on("order_approved", addOrUpdateOrder);
      socketByEventId.on("order_rejected", addOrUpdateOrder);
      socketByEventId.on("order_completed", addOrUpdateOrder);
      socketByEventId.on("song_hidden", (socketItem) => {
        setState((s) => ({
          ...s,
          hidden: [...s.hidden, socketItem.item],
        }));
      });
      socketByEventId.on("song_unhidden", (socketItem) => {
        setState((s) => ({
          ...s,
          hidden: s.hidden.filter((x) => x !== socketItem.item),
        }));
      });
    }
  }, [eventId]);

  const addOrUpdateOrder = (socketItem) =>
    setState((state) => {
      const data = [...state.data];
      const index = data.indexOf(
        data.find((x) => x.order.id === socketItem.order.id)
      );
      if (index > -1) {
        data.splice(index, 1, socketItem);
      } else {
        data.splice(0, 0, socketItem);
      }

      return { ...state, data };
    });

  const handleHide = (id, item) => () => {
    hide({ id, item });
    setState((s) => ({
      ...s,
      hidden: [...s.hidden, item],
    }));
  };

  const handleUnhide = (id, item) => () => {
    unhide({ id, item });
    setState((s) => ({
      ...s,
      hidden: s.hidden.filter((x) => x !== item),
    }));
  };

  const getSortedRequests = useCallback((status) => {
    const requestsByStatus = state.data.filter(
      (item) => item.order.status === status
    );

    const paidRequests = requestsByStatus.filter((item) => item.order.price);
    const freeRequests = requestsByStatus.filter((item) => !item.order.price);

    return [
      ...paidRequests.sort((a, b) => a.order.createdAt - b.order.createdAt),
      ...freeRequests.sort((a, b) => a.order.createdAt - b.order.createdAt),
    ];
  }, [state.data]);

  return (
    <Grid
      container
      p={4}
      spacing={4}
      justifyContent="center"
      sx={{ maxWidth: "1600px", paddingTop: "80px" }}
    >
      {state.data && state.playlists ? (
        <>
          <Grid item xs={12} md={12}>
            {tableStatuses.map((status) => {
              return (
                <RequestTable
                  tableTitle={status}
                  isKaraoke={isKaraoke}
                  isSports={isSports}
                  tableRows={getSortedRequests(status)}
                />
              );
            })}
          </Grid>
          <Grid item xs={12} md={12}>
            {state.playlists.map((playlist, i) => (
              <Grid item xs={12} key={playlist.id}>
                <TableContainer
                  component={Paper}
                  sx={{ marginTop: i > 0 ? 4 : 0, padding: 4 }}
                >
                  <Typography align="center" sx={{ marginBottom: 3 }}>
                    {new Date(playlist.startTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    -{" "}
                    {new Date(playlist.endTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    Playlist
                  </Typography>
                  <Table>
                    <TableBody>
                      {playlist.playlist.map((track) => (
                        <TableRow
                          key={track.item}
                          sx={{
                            opacity: state.hidden?.includes(track.item)
                              ? 0.75
                              : 1,
                          }}
                        >
                          <TableCell>{track.item}</TableCell>
                          <TableCell>
                            {state.hidden?.includes(track.item) ? (
                              <IconButton
                                color="secondary"
                                onClick={handleUnhide(playlist.id, track.item)}
                                title="Mark as available to order"
                              >
                                <VisibilityIcon
                                  sx={{ width: 20, height: 20 }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                color="secondary"
                                onClick={handleHide(playlist.id, track.item)}
                                title="Mark as unavailable to order"
                              >
                                <VisibilityOffIcon
                                  sx={{ width: 20, height: 20 }}
                                />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Loader />
      )}
    </Grid>
  );
}
