import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const PlacesAutocomplete = (props) => {
  const [state, setState] = useState({ options: [] });

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: "AIzaSyAgSDkBpMqDPZdvgaimA3tIFLVUa5xDMM8",
    debounce: 250,
  });

  useEffect(() => {
    if (placePredictions.length) {
      setState((s) => ({
        ...s,
        options: placePredictions.map((x) => ({
          label: x.description,
          value: x.place_id,
        })),
      }));
    }
  }, [placePredictions]);

  const handleChangeItem = (e, value) => {
    if (value) {
      placesService?.getDetails(
        {
          placeId: value.value,
        },
        (placeDetails) => {
          props.setCenter({
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
          });
        }
      );
    }
  };

  const handleChangeOptions = (e) => {
    getPlacePredictions({ input: e.target.value });
  };

  return (
    <Autocomplete
      disablePortal
      id="place-dropdown"
      options={state.options}
      onChange={handleChangeItem}
      value={state.value}
      renderInput={(params) => (
        <TextField
          margin="dense"
          fullWidth
          variant="outlined"
          label="Search Places on Map"
          id="place"
          type="text"
          onChange={handleChangeOptions}
          {...params}
        />
      )}
    />
  );
};

export default PlacesAutocomplete;
