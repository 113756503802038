import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

export default function DeleteModal(props) {
  const [state, setState] = useState({});

  const handleConfirm = async () => {
    setState((s) => ({
      ...s,
      isLoading: true,
    }));
    await props.handleConfirm();
    props.handleClose();
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete <b>{props.name}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
