import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  shouldOpenAuthForm: false,
  shouldOpenProfileSettings: false,
  idToken: "",
  expiresIn: "",
  email: "",
};

const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.idToken = action.payload.idToken;
      state.expiresIn = action.payload.expiresIn;
      state.email = action.payload.email;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.idToken = "";
      state.expiresIn = "";
    },
    openForm(state) {
      state.shouldOpenAuthForm = true;
    },
    closeForm(state) {
      state.shouldOpenAuthForm = false;
    },
    toggleProfileSettings(state) {
      state.shouldOpenProfileSettings = !state.shouldOpenProfileSettings;
    },
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
