import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Button,
  CardContent,
  CardActions,
  Grid,
  Card,
  CardMedia,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import { getUser, getUsers, updateUser } from "../api";
import { Edit } from "@mui/icons-material";
import UserModal from "../components/UserModal";
import Loader from "../components/common/Loader";

export default function UsersPage() {
  const [state, setState] = useState({});

  const usernameOrEmailInputRef = useRef();

  const handleSearch = async () => {
    const data = usernameOrEmailInputRef.current.value
      ? [await getUser(usernameOrEmailInputRef.current.value)]
      : await getUsers();
    setState((s) => ({
      ...s,
      data,
    }));
  };

  const handleEdit = (item) => () => {
    setState((s) => ({
      ...s,
      open: true,
      user: item,
      handleConfirm: async (body) => {
        const user = await updateUser(body);
        s.data.splice(
          s.data.indexOf(s.data.find((x) => x.id === user.id)),
          1,
          user
        );
      },
    }));
  };

  const handleClose = () => {
    setState((s) => ({
      ...s,
      open: false,
      user: undefined,
      handleConfirm: undefined,
    }));
  };

  useEffect(() => {
    (async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        const data = user.role === "admin" ? await getUsers() : [];
        setState((s) => ({
          ...s,
          data,
          role: user.role,
        }));
      }
    })();
  }, []);

  return (
    <>
      <Grid
        container
        p={4}
        spacing={4}
        sx={{ maxWidth: "1600px", paddingTop: "80px" }}
        justifyContent="center"
      >
        {["admin"].includes(state.role) && (
          <Grid item xs={12}>
            <Card
              variant="outlined"
              sx={{
                display: "flex",
              }}
            >
              <TextField
                autoComplete="off"
                margin="dense"
                id="name"
                label="Username or Email"
                type="text"
                variant="outlined"
                color="secondary"
                size="small"
                inputRef={usernameOrEmailInputRef}
                sx={{
                  margin: 1,
                }}
                InputProps={{
                  style: {
                    color: "#d8c8f4",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                type="submit"
                onClick={handleSearch}
                variant="outlined"
                color="secondary"
                size="small"
                sx={{
                  margin: 1,
                }}
              >
                Search
              </Button>
            </Card>
          </Grid>
        )}
        {state.data ? (
          state.data.map((item) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              key={item.id}
              sx={{ background: "transparent" }}
            >
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  backgroundColor: "#4b445b",
                }}
              >
                <CardMedia
                  component="img"
                  image={item.avatar?.uri}
                  alt={item.username}
                  sx={{ width: 200, height: 200, margin: 1, borderRadius: 1 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minWidth: "1px",
                  }}
                >
                  <CardContent
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color="secondary"
                      noWrap={true}
                    >
                      {item.username}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="secondary"
                      mb={1}
                      noWrap={true}
                    >
                      {item.email}
                    </Typography>
                    <Typography variant="body2" color="secondary" mb={1}>
                      {new Date(item.createdAt).toLocaleString([], {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{}}>
                    {["admin"].includes(state.role) && (
                      <IconButton
                        aria-label="edit"
                        onClick={handleEdit(item)}
                        color="secondary"
                        sx={{ marginLeft: "auto" }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                  </CardActions>
                </Box>
              </Card>
            </Grid>
          ))
        ) : (
          <Loader />
        )}
      </Grid>
      {state.open && (
        <UserModal
          venueId={state.venueId}
          user={state.user}
          handleConfirm={state.handleConfirm}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
