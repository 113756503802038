import React from "react";
import AppBar from "./AppBar";
import { Grid } from "@mui/material";

export default function Layout(props) {
  return (
    <>
      <AppBar />
      <Grid container justifyContent="center">
        {props.children}
      </Grid>
    </>
  );
}
