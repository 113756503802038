import React, { useEffect, useState } from "react";
import UserContext from "./UserContext";
import { getProfile, checkToken } from "../api";
import LogRocket from "logrocket";

export default function UserContextProvider({ children }) {
  const setUserFromLocalStorage = () => {
    const user = localStorage.getItem("user");
    if (!user) return;

    try {
      (async () => {
        await checkToken();
        const parsedUser = JSON.parse(user);
        setState((state) => ({
          ...state,
          user: { ...parsedUser },
        }));
      })();
    } catch (e) {}
  };

  const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setState((state) => ({
      ...state,
      user: { ...user },
    }));
  };

  const unsetUser = () => {
    localStorage.removeItem("user");
    setState((state) => ({
      ...state,
      user: undefined,
      fetchedUser: false,
    }));
  };

  const [state, setState] = useState({
    setUser,
    unsetUser,
  });

  useEffect(() => {
    setUserFromLocalStorage();
  }, []);

  useEffect(() => {
    if (state.user && !state.fetchedUser) {
      // if (state.user.venueId) {
      //   (async () => {
      //     const venue = await getVenue(state.user.venueId);
      //     setState((s) => ({
      //       ...s,
      //       user: { ...s.user, ...venue, avatar: venue.logo },
      //       fetchedUser: true,
      //     }));
      //   })();
      // } else {
      (async () => {
        const user = await getProfile(state.user.userId);
        setState((s) => ({
          ...s,
          user: { ...s.user, ...user },
          fetchedUser: true,
        }));
        if ((process.env.REACT_APP_ENV || "prod") === "prod") {
          if (user?.role === "dj") {
            LogRocket.init("umqqfh/viby-music");
            LogRocket.identify(user.id);
          }
        }
      })();
      // }
    }
  }, [state.user, state.fetchedUser]);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}
