import React from "react";
import { Grid, Typography } from "@mui/material";

export default function TermsOfUsePage() {
  return (
    <Grid
      container
      p={4}
      spacing={4}
      sx={{ maxWidth: "1600px", paddingTop: "80px" }}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          color="secondary"
          style={{ whiteSpace: "pre-line" }}
        >
          These General Terms of Use and Legal Information (hereinafter referred
          to as the "General Terms") apply to the VIBY Ltd website (hereinafter
          VIBY), and the related mobile application. The site belongs to VIBY.
          By using the site, you accept and expressly agree to these terms of
          use. If you do not agree, please refrain from using it.{"\n"}
          This makes VIBY the VIBY website and mobile application (hereinafter
          referred to as the "Platform") available to users (hereinafter "User"
          or “Users").{"\n"}
          {"\n"}
          1. About{"\n"}
          VIBY is a technology company whose main activity is the development
          and management of a technology platform through which certain
          restaurants, bars, restaurants and discos in a number of territories
          can offer their products and / or services through mobile or web
          application of the platform (hereinafter "APPENDIX"). ") And in an
          ancillary manner, where applicable and if requested by the ANNEX by
          users of the ANNEX and users of the said establishments, bars,
          restaurants and discos, act as an intermediary in the planned or
          immediate delivery of the products.{"\n"}
          VIBY has developed a Platform through which various merchants with
          whom VIBY may enter into a commercial agreement for the use of the
          platform offer a selection of services and / or products. Users may
          request services from such merchants in person through a mandate given
          by them to a third party when placing an order through the Platform.
          In such cases, VIBY acts only as an intermediary and is therefore not
          in a position to assume or assume any responsibility for the quality
          of the services or for the proper provision of the services offered
          directly by the traders or by such third parties.
          {"\n"}
          In addition, VIBY is a technology platform for mediation in the
          conclusion of on-demand service contracts by remote electronic means.
          Its purpose is to make it easier for those in need of assistance for
          their orders, services, purchases (hereinafter referred to as
          "Consumers") to carry out their orders by delegating to such third
          parties who are willing to voluntarily fulfill the mandate given to
          them. by Users (hereinafter referred to as the “Mandates”).{"\n"}
          In this way, the Mandates are a network of self-employed professionals
          who work with VIBY. When they wish to provide their services, they
          enter the VIBY platform and undertake to perform within a certain
          period of time the service entrusted to them by the User through the
          above-mentioned mandate. As a result, VIBY cannot be held responsible
          for the deadlines, as they will depend on the successful activities of
          the service providers, the preparation of the contracts, the Mandates
          themselves and the information provided by the users when placing
          orders. In this regard, the deadlines specified on the Site are
          provided for indicative purposes only.{"\n"}
          VIBY hereby confirms that it has obtained all necessary licenses to
          conduct its business in the countries in which it operates.{"\n"}
          In these General Terms of Use, both Users and Mandates will be
          referred to as Users of the Platform.{"\n"}
          2. Terms of use{"\n"}
          By accessing the Platform and voluntarily creating an account, all
          Users acknowledge and explicitly and unequivocally agree to these
          Terms of Use and Negotiation, as well as to the Privacy Policy and
          Cookie Policy.{"\n"}
          3. User access and registration{"\n"}
          In order to be a User of the Platform, it is essential that you meet
          the following requirements:{"\n"}• Consumers must be at least 18 years
          old.
          {"\n"}• Users must correctly fill in the required fields of the
          registration form, requesting personal data such as user name, email
          address and bank card number.{"\n"}• Users must agree to these Terms
          of Use and Contract.
          {"\n"}• Users must agree to the privacy policy{"\n"}
          The User guarantees that all information about his identity and
          capacity provided to VIBY in the registration forms of the Platform is
          correct, accurate and complete. In addition, Users undertake to update
          their data.
          {"\n"}
          If a User provides any incorrect, inaccurate or incomplete
          information, or if VIBY considers that there are reasonable grounds to
          doubt the truth, accuracy or completeness of such information, VIBY
          may deny the User current or future access to and use of the Platform
          or any of its content and / or services.{"\n"}
          When registering on the Platform, a users must choose a username and
          password. Both the username and password are strictly confidential,
          personal and non-transferable. To increase the security of accounts,
          VIBY recommends that users do not use the same login credentials as on
          other platforms. If a User uses the same login credentials as on other
          platforms, VIBY will not be able to guarantee the security of the
          account or to ensure that the User is the only person logging in to
          their account.{"\n"}
          Users undertake not to disclose the details of their account or to
          allow access to it to third parties. Users are solely responsible for
          any use of such details or services of the Site that may be made by
          third parties, including statements and / or content entered into the
          Platform, or for any other action performed on their username and / or
          password.{"\n"}
          Users are the sole owners of the content they have entered on the
          Platform. In addition, by registering on the Platform and agreeing to
          these Terms, the User provides VIBY, in connection with the content
          that he / she may provide, a free global, irrevocable and transferable
          license, with the right to sublicense, use, copy, modifying, creating
          derivatives, distributing, advertising and operating in any way that
          VIBY deems appropriate, with or without further communication to the
          User, and without having to pay any amounts for such uses.{"\n"}
          VIBY cannot guarantee the identity of the registered Users and
          therefore will not be responsible for the use of the registered
          identity of the User by unregistered third parties. Users undertake to
          immediately inform VIBY using the communication channels provided by
          VIBY if their credentials are stolen, disclosed or lost.{"\n"}
          3.1 Profile{"\n"}
          To complete their registration on the Platform, Users must provide
          certain details, such as: username, email address and bank card
          details, among others. Once the registration process is complete, all
          Users will be able to access, complete and edit their profile as they
          see fit. VIBY does not store data on consumer payments that will be
          processed and stored by the payment service provider, as described in
          these Terms and in the Privacy Policy.
          {"\n"}
          3.2. Credit card theft or misappropriation{"\n"}
          As VIBY cannot guarantee the identity of registered Users, Users are
          obliged to inform VIBY if they have evidence that the credit card
          associated with their VIBY account has been stolen and / or
          fraudulently used by a third party. Therefore, as VIBY and its payment
          platform are active in protecting Consumers by using appropriate
          security measures, if the User does not inform VIBY about the missing
          card, VIBY will not be liable for improper use that may be made by
          third parties in the User's account. VIBY offers Consumers to report
          any theft, robbery or suspected misuse of their credit card to the
          police.{"\n"}
          If necessary, VIBY undertakes to assist the Users and to cooperate
          with the competent authorities, if necessary, in order to provide
          reliable evidence of incorrectly applied fee. In the event of fraud,
          VIBY reserves the right to take any appropriate action if it has been
          adversely affected by the misuse of the site.{"\n"}
          4. Operation of the Service. Terms of reference.{"\n"}
          When a user who is registered as a User needs help with an order, he
          must go to the online platform or the relevant application of VIBY and
          request the service using the specified electronic methods. The main
          service includes collection and subsequent execution, provided that
          they are located on the territory of the site with which VIBY works.
          {"\n"}
          If the User wishes to contact Mandator or VIBY after the completion of
          the order, he / she must use the contact form available on the
          Platform and contact the customer support service.{"\n"}
          When placing an order, Users voluntarily agree with the published
          price of the service on the VIBY platform.{"\n"}
          If the product and / or service is refused by the Mandator, the User
          is not charged for it.{"\n"}
          If the User is not on the territory of the establishment, bar,
          restaurant or disco when the service is performed, it is considered
          performed and the User should have claims to this.{"\n"}
          The text message can also be added to the services. VIBY will not
          tolerate and publish obscene or inappropriate messages that do not
          comply with generally accepted norms and / or damage someone's
          prestige and they will not be published or deleted.{"\n"}
          By posting a message the User undertakes:{"\n"}• To observe the
          Bulgarian legislation, the applicable foreign laws, the present
          General Terms and Conditions, the internet ethics, the rules of morals
          and good manners;{"\n"}• Not to harm the good name of another and not
          to call for a violent change of the constitutional order, to commit a
          crime, to violence against the person or to incite racial, national,
          ethnic or religious hatred;{"\n"}• Not to preach fascist or other
          anti-democratic ideology;{"\n"}• To notify VIBY immediately of any
          case of committed or discovered violation;{"\n"}
          If the User receives confirmation that the order will be fulfilled,
          but this does not happen, he must immediately inform VIBY so that the
          Platform can take appropriate steps. VIBY reserves the right to verify
          any communications made by the User for verification and monitoring
          purposes.{"\n"}
          5. Reimbursement of the amount of paid service{"\n"}
          When a paid service provided and published on the VIBY platform does
          not materialize, for reasons independent of VIBY, in these cases,
          including the exercise of the right of withdrawal by the buyer, VIBY,
          as the owner of the electronic platform through which the transaction
          was concluded, and temporarily storing the paid amounts, if he has not
          yet transferred them to the seller's account, has the right, but not
          the obligation, to refund the amount paid by the Buyer by taking it to
          his virtual wallet or, at the explicit request of the latter and upon
          presentation of a document proving payment, transfer it to the bank
          account specified by him. If the amount is paid online by the buyer
          via a bank card through an integrated in VIBY virtual POS, then the
          refund can be made directly to the same bank card. The amounts
          accumulated in the virtual wallet can only be used for VIBY shopping.
          No interest or fees are due for the period from the advance payment to
          the transfer of the amount to the promoter and for the storage of
          amounts in the virtual portfolio.{"\n"}
          6. Prices of services and charging{"\n"}
          Users can register for the Platform and use it for free, although this
          is subject to review by VIBY at any time. Users may be required to pay
          for certain services on the Platform as provided in these Terms of
          Use.{"\n"}
          The use of the Platform by Mandates and Merchants may incur costs
          based on the country from which they wish to use the Platform to
          provide services.
          {"\n"}
          The User must pay only for each service requested through the
          Platform, for his / her use to order services and communicate through
          the Platform.{"\n"}
          The total price of each service is published in the appendix.{"\n"}
          VIBY reserves the right to change the prices of the Platform at any
          time. Such changes shall take effect immediately upon publication.
          {"\n"}
          6.1. Payment platforms{"\n"}
          Payment for services offered on the Platform is made temporarily to
          VIBY, which then sends them to the sites with which it has a
          commercial contract. The linked sites authorize VIBY to accept
          payments on their behalf. Therefore, the payment of the price of any
          service done correctly to VIBY will fulfill the obligation of the User
          to pay the said price of the related sites.{"\n"}
          In the same way, the payment of the User releases him from any
          obligations towards the Mandate, as the full payment by the User
          fulfills any obligation that the User may have towards the objects and
          / or the Mandates.{"\n"}
          Payment by users for services is received on VIBY accounts through an
          electronic money institution. Electronic money institutions are
          authorized to provide, directly or indirectly, regulated payment
          services in all territories in which VIBY operates and comply with the
          applicable legislation applicable to payment services for platforms
          such as VIBY *.
          {"\n"}* If such institutions are not authorized, VIBY does not assume
          any responsibility for such lack of authorization or license, and the
          sole and exclusive responsibility lies with the electronic money
          institutions.{"\n"}
          If the user has purchased one of the options for loading an amount in
          the virtual wallet, he can choose the option to pay for a service, and
          the price for the service will be deducted from the balance in the
          virtual wallet.
          {"\n"}
          Using the payment provider concluded by him for this purpose and the
          sole purpose of verifying the means of payment provided, VIBY reserves
          the right, as a fraud prevention measure, to request prior
          authorization for payment for services ordered through the platform.
          {"\n"}
          In order to provide users with better support, VIBY will be their
          first point of contact and will take responsibility for payments made
          on the Platform. Such liability shall include recovery, recovery,
          cancellation and settlement of disputes at an early stage and shall be
          without prejudice to any action that VIBY may take. we treat local
          retailers as the only physical sellers of the products ordered by the
          Consumers.{"\n"}
          In accordance with the above, in the event of a dispute, VIBY will
          provide the first line of support and reimburse the User if it deems
          it appropriate.
          {"\n"}
          If a User has any problems with the progress of his / her order, he /
          she may contact the VIBY Customer Support Service through the methods
          provided to the Users of the Platform.{"\n"}
          7. Price of the products and / or services appearing on the Platform
          {"\n"}
          All prices listed in the Platform include all taxes that may be
          applicable based on the territory from which the User operates, and in
          any case will be denominated in the currency in force in the territory
          from which the User operates.{"\n"}
          In accordance with point 6 above, the prices applicable to each
          service must be published on the Platform, depending on the specific
          characteristics mentioned above, and be applied automatically at the
          last stage of the negotiation process.{"\n"}
          However, the prices of the products sold in the sites (for example,
          specified promotions in the site), shown on the VIBY platform, can
          only be indicative. In any case, they correspond to the products sold
          at the sites and are determined only by them. The user can contact the
          Mandator to confirm the final price of the ordered products.{"\n"}
          The consumer accepts that in any case, the price of some products may
          change in real time based on the company that sells them and the
          available stock. The user can contact VIBY for any information about
          the order.{"\n"}
          8. Promotional codes and / or other offers or discounts{"\n"}
          VIBY may unilaterally provide credits at any time for use within the
          Platform to certain Users. The consumer acknowledges and accepts that
          the credits must be used within the validity of the credit.{"\n"}
          The promotional codes and / or other offers or discounts offered in
          the Platform must be entered correctly in the application before
          placing the order. Otherwise, they will not take effect and the User
          will not be able to use them.{"\n"}
          Whenever VIBY requests cancellation in accordance with section 9
          below, the User warrants that the promotional code and / or other
          offer or discount is valid for future use. When the cancellation is at
          the request of the User, Section 9 below will apply.{"\n"}
          VIBY reserves the right to unilaterally revoke promotional codes and /
          or other offers or discounts if it becomes aware of their misuse (such
          as, inter alia, a promotional code used by someone other than a
          legitimate recipient, mass communication of codes or sales of codes or
          discounts), at the end of the above period. In addition, it reserves
          the right to impose sanctions on Users for the amount for which VIBY
          has been defrauded as a result of such use.{"\n"}
          VIBY does not assume any responsibility if in case of force majeure or
          other events beyond its control or whose need is justified, it is
          forced to cancel, shorten, extend or change the terms of the
          promotions.{"\n"}
          In particular, VIBY shall not be liable if the website and / or the
          application are not available at any time during promotions or for a
          malfunction in the automated promotional system.{"\n"}
          Promotional codes cannot be combined. The user can use only 1 (one)
          promotional order code.{"\n"}
          Promotional codes may not be used for promotions offered in the ANNEX.
          {"\n"}
          It is forbidden for the same user to have more than 1 (one)
          registration. This is a precautionary measure in order to avoid
          illegal benefits that are unscrupulous, malicious or contrary to our
          general terms.{"\n"}
          9. Right of withdrawal and cancellation of orders{"\n"}
          In accordance with the nature of the service offered by VIBY, the User
          is aware that after the Mandator voluntarily accepts an order, the
          execution of the purchase mandate is considered started and therefore
          the User is no longer entitled to withdraw the service request free of
          charge.{"\n"}
          If the user cancels an order after the Mandator has accepted it for
          processing, the user will be charged a cancellation fee, which is
          equal to the value of the service ordered by him. The User will be
          informed about the acceptance by the Mandate through the Platform. The
          user can see the cancellation costs on the Platform.{"\n"}
          The total cancellation price will appear on the user's screen when
          clicking the "Cancel" button on the platform. The User will be shown
          the fee that will be charged based on the factors described above.
          {"\n"}
          The matador reserves the right to cancel an order without having to
          state a valid reason. In case of cancellation without good reason in
          the case of the Mandator, the User will be entitled to a refund of the
          amount paid.{"\n"}
          VIBY has official claim forms available for users, in the official
          languages ​​of the countries in which VIBY operates, in connection
          with the service it offers. Users can request the above-mentioned
          claim forms through the VIBY customer support service and the option
          to access them will be sent automatically. The user's email must
          indicate the exact location from which the request is made, which must
          be the same as the place where the service is to be performed.{"\n"}
          In case of doubt, the claim must be made from the last place.{"\n"}
          10. Special conditions of the service for purchasing services through
          VIBY
          {"\n"}
          The User is aware and accepts that the descriptions and, where
          applicable, the prices or photos of the products and / or services
          offered on the Platform are provided on the basis of the information
          and documents provided by the establishments and that VIBY cannot
          therefore provide any warranties against any inaccuracies in such
          descriptions, prices and / or photographs.
          {"\n"}
          In addition, the User accepts that all services / products appearing
          on the VIBY Platform are subject to availability and in this regard
          accepts the possibility that during the execution of the order, the
          product and / or service may not be available in the restaurant.{"\n"}
          {"\n"}
          Price and method of payment{"\n"}
          The price of the product and / or service must be indicated on the
          platform of the establishment. However, the User knows that the price
          of some of the services and products may vary in real time due to the
          availability of stocks in the restaurants appearing on the platform,
          and that in any case the User will always be notified of the final
          costs before payment.{"\n"}
          The user can pay for the products and / or services with a credit /
          debit card. When placing the order, the User will be informed about
          the various payment options available in the territory from which the
          service is requested. To pay by credit / debit card, the User must
          provide the card details through the platform as a payment method
          associated with his account. VIBY does not store card numbers on its
          servers and can only see the last four digits of the card. Complete
          information will be stored on the servers of the payment service
          provider that makes payments on behalf of VIBY. Credit card users will
          not be charged extra for choosing this payment method. All this will
          be in accordance with the terms of the payment platform set out in
          point 6.1.{"\n"}
          If for some reason payment for the service cannot be taken from the
          User, the User's account will be blocked until the situation is
          resolved and{"\n"}
          11. Special conditions of the Top up service{"\n"}
          VIBY provides Platform Users with a category in which they can choose
          from a range of prepaid products / services. Each of the options will
          be described and can be chosen by the Users. Top ups represent a
          different set of prepaid services / products of different value. When
          the User decides to choose to pay for a service / product through his
          wallet, the amount will be deducted from the total availability in the
          wallet. The amount remaining in the portfolio can be used for
          subsequent orders if their value is less than or equal to the amount
          remaining in the portfolio.{"\n"}
          Users will find all the information related to prepaid options in the
          "My Account" section of the application. Here they can check the
          remaining amount in their account.{"\n"}
          The user may request a transfer of the remaining amount in his wallet.
          The user must contact the VIBY contact form and request a refund of
          the balance by providing bank details to which the transfer will be
          made.{"\n"}
          Unless otherwise provided in this section, the General Terms of Use
          apply to the Top up service.{"\n"}
          12. Purchases of alcohol{"\n"}
          Consumers who place an order that includes the purchase of alcoholic
          beverages through the platform must be of legal age. This means that
          they must be at least the age provided by the local legislation
          applicable in the territory where the User places the order. When
          placing an order that includes alcoholic beverages, the User confirms
          that he is of the age determined by the local legislation applicable
          in the territory where the User places the order. VIBY reserves the
          right to refuse the order to purchase alcohol to any person who cannot
          prove that he or she is of the age determined by the local legislation
          applicable in the territory where the User places the order.{"\n"}
          This clause will apply in exactly the same way to any other similar
          product and / or service ordered by a User through the Platform, which
          is reserved for persons over 18 years of age in accordance with
          applicable law.{"\n"}
          {"\n"}
          13. Payment to VIBY Trading Partners{"\n"}
          By creating a registration with VIBY as a trading partner, the User
          agrees to all general terms and conditions that VIBY uses. This
          includes interest, which is paid upon reaching a certain amount, with
          which the User agrees. Any adjustment of the interest fee, as well as
          the minimum amount required to make a transfer can be made only after
          the explicit consent of the User by sending a message on the platform,
          with which the User agrees.{"\n"}
          14. Solidarity in VIBY{"\n"}
          VIBY cooperates with various institutions, corporate groups and
          non-governmental organizations in charity and solidarity projects,
          donations and non-profit campaigns in some of the countries in which
          it operates (subject to applicability in each country).{"\n"}
          VIBY is not responsible for the end use or the outcome of such
          campaigns. It does not provide any security or guarantee, nor does it
          accept any legal liability, nor does it have any control over the way
          in which donations are used by the aforementioned institutions.{"\n"}
          15. Geolocation{"\n"}
          VIBY may collect, use and share accurate location data, including
          real-time geographic location on the User's computer or mobile device,
          provided that this is permitted by the User. Mentioned location data
          can be collected and used by VIBY to show users the location of the
          point of origin and / or destination of a job. In this regard, the
          Users expressly agree to share their geolocation data with other Users
          and Providers in order to successfully complete the requested task at
          any time. Users can choose to deactivate the geolocation of their
          devices as provided in the Privacy policy.{"\n"}
          16. Obligations of the consumer{"\n"}
          Users will be fully responsible for the proper use and access to their
          profile and other content of the Platform in accordance with
          applicable law, whether national or international, of the country from
          which they use the Platform, as well as the principles of good faith,
          morality, custom and public order. In particular, they undertake to
          comply diligently with these General Terms of Use.{"\n"}
          Users are responsible for the correct entry of their individual
          usernames and passwords, which cannot be transferred and must be
          complex enough, and for not using the same username and password as in
          other platforms, all to protect your fraudulent use account by third
          parties who do not belong to the Platform.{"\n"}
          Users must refrain from using their profile and other content on the
          Platform for illegal purposes or with illegal results that harm the
          rights and interests of third parties, or that may in any way harm,
          disable, affect or impair the Platform and its content. and services.
          In addition, they are prohibited from interfering with the normal use
          or use of the Platform by other users.{"\n"}
          VIBY may not be considered to be editorially responsible and expressly
          states that it does not identify with opinions that may be issued by
          Users of the Platform, the consequences of which are the sole
          responsibility of their publishers.{"\n"}
          All persons in breach of the above obligations shall be liable for any
          loss or damage caused by them. VIBY shall not be liable for any
          consequences, losses or damages that may result from such illegal use
          or access by third parties.{"\n"}
          In principle, consumers undertake, for example, without limitation:
          {"\n"}• Refrain from changing or modifying the Platform, in whole or
          in part, by circumventing, deactivating or in any other way tampering
          with its features or services;{"\n"}• Refrain from infringing
          industrial and intellectual property rights or personal data
          protection legislation;{"\n"}• Refrain from using the Platform to
          insult, slander, intimidate or harass other Users or attack their
          image; - Refrain from accessing other users' email accounts;
          {"\n"}• Refrain from introducing computer viruses, damaged files or
          any other software that may cause damage or changes to the content or
          systems of VIBY or third parties;{"\n"}• Refrain from sending mass and
          / or duplicate emails to a certain number of people or from sending
          email addresses to third parties without their consent;{"\n"}• Refrain
          from advertising goods or services without the prior consent of VIBY.
          {"\n"}
          Each User may file a report for another User if he considers that the
          latter violates these General Terms of Use. In the same way, each User
          can inform VIBY about any abuse or violation of these conditions
          through the Contact Form. VIBY will review such reports as soon as
          possible and will take all steps it deems appropriate, reserving the
          right to remove and / or suspend any User of the Infringement
          Platform. compliance with these General Terms of Use. In addition,
          VIBY reserves the right to remove and / or suspend any message with
          illegal or offensive content without the need for prior notice or
          subsequent notification.{"\n"}
          17. Unsubscribe from users{"\n"}
          Users can unsubscribe from the Platform using the Platform Contact
          Form
          {"\n"}
          18. Liability of VIBY{"\n"}
          Users are responsible for having the necessary services and equipment
          for surfing the Internet and accessing the platform. Users can report
          incidents or problems accessing the Platform to VIBY using the contact
          channels provided to users, and VIBY will analyze the incident and
          instruct the User on how to resolve it as quickly as possible.{"\n"}
          VIBY does not control and is not responsible for the content uploaded
          by the Users through the Platform, and the Users are solely
          responsible for the legality of such content.{"\n"}
          VIBY will not be liable for any interruptions in the service, errors
          in the connection, unavailability or malfunctions in the Internet
          access service or interruptions in the Internet or for any other
          issues beyond its control.
          {"\n"}
          VIBY is not responsible for security errors that may occur or for any
          damage that may be caused to the user's computer system (hardware and
          software) or to the files or documents stored in it as a result of :
          {"\n"}• The presence of a virus in the computer system or mobile
          handset of the user, used to connect to the content and services of
          the Platform;{"\n"}• Browser malfunction;{"\n"}• The use of outdated
          versions.{"\n"}
          19. Responsibility for the content{"\n"}
          VIBY does not control (and is not obligated to control) how Users use
          the Platform. Therefore, it does not guarantee that Users use the
          Platform in accordance with these General Terms of Use or in a
          diligent and / or reasonable manner. VIBY does not verify (and is not
          required to verify) the identity of Users or the authenticity, current
          validity, completeness and / or authenticity of the data provided by
          them.{"\n"}
          VIBY disclaims any liability for damages of any kind that may arise
          from the illegal use of the Platform Users or due to the information
          provided by the Users of other Users about themselves as false,
          invalid, incomplete and / or false, and - specifically and without
          limitation, for any losses or damages of any kind that may occur due
          to the fact that the User presents himself as a third party in any
          type of communication made through the Platform. In particular, VIBY
          assumes no responsibility for the use of the Platform or for any
          orders that may be made by a third party from the User's account.
          {"\n"}
          Notwithstanding the foregoing, VIBY reserves the right to restrict in
          whole or in part the access of certain Users to the Platform, as well
          as to cancel, suspend, block or remove certain types of content
          through appropriate technological tools if it becomes known or there
          are indications that the activity or the information stored is illegal
          or detrimental to the rights or property of a third party. In this
          regard, VIBY may create any filters that may be necessary to prevent
          the use of the service to upload illegal or harmful content to the
          Internet. By providing content through the Platform, Users assign to
          VIBY all exploitation rights arising from the content thus provided.
          {"\n"}
          20. No guarantee for assignments or payments{"\n"}
          VIBY makes no warranties as to the authenticity, accuracy, novelty,
          reliability, legality or non-infringement of the rights of third
          parties by the Mandates. In this regard, Consumers state that they
          understand that VIBY is a technology platform whose main activity is
          mediation; in other words, it puts Users and Mandates in contact with
          each other; and therefore assumes no liability arising from the
          information provided by the Mandates or for any loss or damage that
          may potentially be incurred by them due to violation of these General
          Terms of Use. When the order is placed on the Platform and accepted by
          the Mandate, a direct connection is established between the User and
          the Mandate. VIBY is not a party to this relationship and simply
          provides the Platform to enable the parties to communicate and place
          as many orders as necessary. Therefore, VIBY will never be responsible
          for the existence of Mandates or for their adequate and satisfactory
          performance of tasks.{"\n"}
          VIBY makes no representations or representations with respect to any
          of the Mandates or their identities or background information.
          Notwithstanding the above, VIBY could potentially carry out additional
          checks and put in place procedures designed to help verify or verify
          the identity of the Mandate. rites. In this regard, when the User
          achieves the status of a Mandator, it simply shows that the said User
          has completed the relevant registration process and has accepted these
          General Terms of Use and all other specific conditions that may apply
          to him. Such a status does not imply any certification or approval by
          VIBY regarding the reliability, suitability and / or safety of such a
          user.{"\n"}
          Therefore, VIBY recommends that users always use their common sense
          and remain vigilant when delegating a task to a particular Mandator.
          {"\n"}
          By becoming a member of the Platform, Users agree that any claims they
          wish to bring to justice arising from the actions or omissions of
          other Users of the Platform or third parties will be limited to such
          Users or third parties, and that no liability cannot be taken against
          VIBY.{"\n"}
          {"\n"}
          21. Rating system{"\n"}
          VIBY applies optimal measures to achieve maximum objectivity in the
          rating:
          {"\n"}
          {"\n"}• User reviews can be left only by users who have certainly
          purchased a published service / product;{"\n"}
          For each customer review, the sales partner has the opportunity to
          write a response if desired;{"\n"}• In case of possible comments with
          obscene content, attacks on ethnic or racial grounds, as well as
          direct insults, there is a possibility to hide the relevant comment
          (after receiving a report from the store);{"\n"}• After satisfying a
          consumer complaint (refund to a client), if the client no longer meets
          the criteria to have the right to publish a review, the review
          published by him is automatically removed from the Grabo.bg system (up
          to 48 hours after the refund to the client through the channel order
          of VIBY);{"\n"}• The reviews shown in an offer do not follow a
          chronological order. Instead, the system gives priority to reviews
          that are close to the overall average rating of the site (for example:
          if a site profile has an average rating of 4.5, priority will be given
          to comments with a rating of 4 or 5; If the average rating is 3.5,
          then comments with a score of 3 and 4 will be displayed as a priority.
          If the average score is lower - comments with low scores).{"\n"}
          {"\n"}
          22. Updates and changes in the Platform{"\n"}
          VIBY reserves the right to change these Terms of Use, Privacy Policy
          and Cookie Policy at any time without notice. Users must carefully
          read these Terms and Conditions when accessing the platform. In any
          case, the adoption of the General Terms and Conditions is an essential
          step that must be taken before accessing the services and content
          available through the VIBY platform.{"\n"}
          In addition, VIBY reserves the right to update, amend or delete the
          information contained in its Platform at any time and without prior
          notice regarding the layout, presentation and conditions of access,
          without incurring any be responsible for it. VIBY does not guarantee
          that there will be no errors or interruptions in accessing the
          Platform or its content, or that it will always be up to date.
          Notwithstanding the above, except in circumstances that make this
          difficult or impossible, VIBY will take all steps to correct such
          errors, restore communication and update the content as soon as it
          becomes aware of errors or inconsistencies or outdated content.{"\n"}
          23. Intellectual property{"\n"}
          VIBY is the owner or licensee of all intellectual and industrial
          property rights included in the Platform, as well as of the content
          that may be accessible through it. The intellectual property rights of
          the Platform, as well as the text, images, graphic design, navigation
          structure, information and content included in it, are the property of
          VIBY, which has the exclusive right to exercise the rights of
          exploitation in any way, in in particular the rights of reproduction,
          distribution, publication and transformation in accordance with the
          Bulgarian legislation on intellectual and industrial property rights.
          {"\n"}
          Notwithstanding the foregoing, VIBY may not own or license content
          such as names or images, among others, to companies with which VIBY
          has no business relationship. In such cases, VIBY acquires the content
          from publicly available sources and in no case will VIBY be considered
          related to any right belonging to VIBY.{"\n"}
          Authorizing a User to access the Platform does not constitute a
          waiver, transfer, licensing or full or partial transfer by VIBY of any
          intellectual or industrial property rights. Deleting, circumventing or
          in any way falsifying the content of the VIBY platform is prohibited.
          In addition, modify, copy, reuse, operate, reproduce waiting for,
          publishing, making second or subsequent publications on, uploading
          files, mailing, transmitting, using, processing or distributing in any
          way all or part of the content included in the VIBY platform for
          public or commercial purposes are also prohibited, except with the
          express written permission of VIBY or, where applicable, that of the
          right holder.{"\n"}
          Any user who shares any content through the Platform claims to have
          the necessary rights to do so, releasing VIBY from any liability with
          regard to the content and legality of the information provided. By
          providing content through the Platform, Users assign to VIBY, free of
          charge and to the maximum extent permitted by applicable law, the
          rights to exploit intellectual or industrial property arising from
          such content.{"\n"}
          24. Separation{"\n"}
          If any of the clauses of these General Terms and Conditions are found
          to be voidable or void by law, such clause (s) will be deemed not to
          have been / have not been included. Such a declaration of invalidity
          will not invalidate the remaining clauses and they will remain valid
          and effective between the parties.{"\n"}
          25. Applicable law{"\n"}
          The relationship between VIBY and the User is managed and interpreted
          in accordance with the General Terms, the construction, validity and
          implementation of which are governed by Bulgarian law; and all
          disputes will be submitted to the courts in Sofia, unless the User
          requests that they be submitted to the courts applicable to his / her
          home address.{"\n"}
          26. Alternative dispute resolution{"\n"}
          All disputes or disagreements arising outside the European Union will
          be submitted to the Bulgarian Arbitration Court at the Chamber of
          Commerce.
        </Typography>
      </Grid>
    </Grid>
  );
}
