import React, { useEffect } from "react";
import AuthForm from "../components/Auth/AuthForm";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";
import { useNavigate } from "react-router-dom";

export default function LoginPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { shouldOpenAuthForm, isAuthenticated } = useSelector(
    (store) => store.auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(authActions.closeForm());
    } else {
      dispatch(authActions.openForm());
    }
  }, [shouldOpenAuthForm, isAuthenticated, dispatch, navigate]);

  return <AuthForm title={props.title} />;
}
