import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { getVenues } from "../api";
import readStreamAsBase64 from "../utils/readStreamAsBase64";
import { AddPhotoAlternate } from "@mui/icons-material";
import resizeFile from "../utils/resizeFile";
import UserContext from "../contexts/UserContext";

export default function PromotionModal(props) {
  const nameInputRef = useRef();

  const [state, setState] = useState({});

  const userContext = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (userContext.user.role === "admin") {
        const venues = await getVenues();
        setState((s) => {
          const venueItem = venues.find((x) => x.id === s.venueId);
          const venue = venueItem && {
            label: venueItem.name,
            value: venueItem.id,
          };
          return {
            ...s,
            venues,
            venue,
          };
        });
      }
    })();
  }, [userContext.user.role]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      ...props.promotion,
      promotionPhotoFile: props.promotion?.image?.uri,
    }));
  }, [props.promotion, props.venueId]);

  const handleVenueChange = (e, value) => {
    setState((s) => {
      const venueItem = s.venues.find((x) => x.id === value.value);
      const venue = venueItem && {
        label: venueItem.name,
        value: venueItem.id,
      };
      return {
        ...s,
        venue,
      };
    });
  };

  const handleChangePromotionPhoto = async (e) => {
    const promotionPhoto = await resizeFile(e.target.files[0]);
    setState((s) => ({
      ...s,
      promotionPhoto,
      promotionPhotoFile: URL.createObjectURL(e.target.files[0]),
      promotionPhotoHasChanged: true,
    }));
  };

  const handleConfirm = async () => {
    if (!nameInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          name: "Name is required.",
        },
      }));
      return;
    }

    if (!(state.venue?.value || state.venueId)) {
      setState((s) => ({
        ...s,
        errors: {
          venueId: "Venue is required.",
        },
      }));
      return;
    }

    setState((s) => ({
      ...s,
      isLoading: true,
      errors: undefined,
    }));
    const base64PromotionPhoto = state.promotionPhotoHasChanged
      ? await readStreamAsBase64(state.promotionPhoto)
      : undefined;
    const body = {
      id: state.id,
      venueId: state.venue?.value || state.venueId,
      name: nameInputRef.current.value,
      base64: base64PromotionPhoto,
    };
    await props.handleConfirm(body);
    props.handleClose();
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>
        {state.id ? "Edit Promotion" : "Create Promotion"}
      </DialogTitle>
      <DialogContent>
        {userContext.user.role === "admin" && state.venues?.length ? (
          <Autocomplete
            disablePortal
            id="venueId-dropdown"
            options={state.venues.map((x) => ({
              label: x.name,
              value: x.id,
            }))}
            value={state.venue}
            disableClearable
            onChange={handleVenueChange}
            renderInput={(params) => (
              <TextField
                margin="dense"
                fullWidth
                variant="outlined"
                label="Venue"
                id="venueId"
                type="text"
                required
                error={!!state.errors?.venueId}
                helperText={state.errors?.venueId}
                {...params}
              />
            )}
          />
        ) : null}
        <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          label="Promotion Photo"
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: "div",
          }}
          inputProps={{
            children: (
              <>
                {state.promotionPhotoFile && (
                  <img
                    alt={state.name}
                    src={state.promotionPhotoFile}
                    height="200"
                    style={{ borderRadius: "8px" }}
                  />
                )}
                <div>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: "4px" }}
                  >
                    <AddPhotoAlternate
                      sx={{ fontSize: "20px", marginRight: "4px" }}
                    />
                    {state.promotionPhotoFile ? "Change" : "Add"}
                    <input
                      type="file"
                      hidden
                      onChange={handleChangePromotionPhoto}
                    />
                  </Button>
                </div>
              </>
            ),
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="Promotion Name"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={nameInputRef}
          defaultValue={state.name}
          required
          error={!!state.errors?.name}
          helperText={state.errors?.name}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
