import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { completeOrder, approveOrder, rejectOrder } from "../api";
import { Check, Close } from "@mui/icons-material";

export default function RequestTable({
  isKaraoke,
  isSports,
  tableRows,
  tableTitle,
}) {
  const handleComplete = (item) => () =>
    completeOrder({
      id: item.order.id,
      userId: item.order.userId,
      venueId: item.order.venueId,
      eventId: item.order.eventId,
    });

  const handleApprove = (item) => () =>
    approveOrder({
      id: item.order.id,
      userId: item.order.userId,
      venueId: item.order.venueId,
      eventId: item.order.eventId,
    });

  const handleReject = (item) => () =>
    rejectOrder({
      id: item.order.id,
      userId: item.order.userId,
      venueId: item.order.venueId,
      eventId: item.order.eventId,
    });

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{ marginTop: 4, padding: 4 }}>
        <Typography align="center" sx={{ marginBottom: 3 }}>
          {`${tableTitle[0].toUpperCase()}${tableTitle.slice(1)}`}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              {tableTitle !== "tip" && <TableCell>Track</TableCell>}
              {isKaraoke ? (
                <>
                  <TableCell>Name</TableCell>
                  {tableTitle === "tip" && <TableCell>Tip</TableCell>}
                </>
              ) : (
                <>
                  <TableCell>Username</TableCell>
                  {!isSports && <TableCell>Message</TableCell>}
                  {tableTitle === "tip" ? (
                    <TableCell>Tip</TableCell>
                  ) : (
                    <TableCell>Price</TableCell>
                  )}
                </>
              )}
              {["initiated", "pending"].includes(tableTitle) && (
                <TableCell></TableCell>
              )}
              {tableTitle === "initiated" && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((item) => (
              <TableRow key={item.order.id}>
                <TableCell style={{ width: 0 }}>
                  {new Date(item.order.updatedAt).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                {tableTitle !== "tip" && (
                  <TableCell>{item.order.item}</TableCell>
                )}
                {isKaraoke ? (
                  <>
                    <TableCell>{item.order.userName}</TableCell>
                    {tableTitle === "tip" && (
                      <TableCell>{item.order.price || "FREE"}</TableCell>
                    )}
                  </>
                ) : (
                  <>
                    <TableCell>{item.order.username}</TableCell>
                    {!isSports && <TableCell>{item.order.message}</TableCell>}
                    <TableCell>{item.order.price || "FREE"}</TableCell>
                  </>
                )}
                {["initiated", "pending"].includes(tableTitle) && (
                  <TableCell style={{ width: 0 }}>
                    <Button
                      onClick={
                        tableTitle === "initiated"
                          ? handleApprove(item)
                          : handleComplete(item)
                      }
                      title="Mark as approved"
                      color="secondary"
                      variant="contained"
                    >
                      <Check
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    </Button>
                  </TableCell>
                )}
                {tableTitle === "initiated" && (
                  <TableCell style={{ width: 0 }}>
                    <Button
                      onClick={handleReject(item)}
                      title="Mark as not approved"
                      color="secondary"
                      variant="contained"
                    >
                      <Close
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
