import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete from "./PlacesAutocomplete";

const CustomMap = ({ center, zoom, handleCoordsChange }) => {
  const [state, setState] = useState({});

  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });

    const marker = new window.google.maps.Marker({
      position: center,
      map,
    });

    const setCenter = (newCenter) => {
      map.setCenter(newCenter);
      marker.setPosition(newCenter);
      handleCoordsChange({
        latitude: newCenter.lat,
        longitude: newCenter.lng,
      });
    };

    setState((s) => ({
      ...s,
      setCenter,
    }));

    // Configure the click listener.
    map.addListener("click", (mapsMouseEvent) => {
      const newCenter = {
        lat: mapsMouseEvent.latLng.lat(),
        lng: mapsMouseEvent.latLng.lng(),
      };
      setCenter(newCenter);
    });
  }, [center, zoom, handleCoordsChange]);

  return (
    <>
      <PlacesAutocomplete
        apiKey="AIzaSyAgSDkBpMqDPZdvgaimA3tIFLVUa5xDMM8"
        setCenter={state.setCenter}
      />
      <div
        ref={ref}
        id="map"
        style={{
          height: "280px",
          width: "100%",
          marginTop: "8px",
          marginBottom: "8px",
          borderRadius: "4px",
        }}
      />
    </>
  );
};

export default CustomMap;
